import { Toaster as HotToaster, Toast, ToastBar } from 'react-hot-toast';
import { FC } from 'react';
import CheckIcon from '@/modules/icons/CheckIcon.tsx';
import AlertIcon from '@/modules/icons/AlertIcon.tsx';
import cn from 'classnames';

type ToastProps = {
  // t: Toast,
  visible: boolean;
  message: string;
};
// =================================================================
// https://react-hot-toast.com
// =================================================================
const containerClasses =
  'max-w-md w-full shadow-lg pointer-events-auto flex ring-black ring-opacity-5 border';

const SuccessToast = ({ visible, message }: ToastProps) => {
  const cls = cn(
    { 'animation-enter': visible },
    { 'animation-leave': !visible },
    containerClasses,
    'border-toast-success-border bg-toast-success-bg text-toast-success-text',
  );
  return (
    <div className={cls}>
      <div className="p-4 w-3 text-toast-success-icon justify-center">
        <CheckIcon className="fill-success" />
      </div>
      <div className="flex-1 w-0 p-4">{message}</div>
    </div>
  );
};

const ErrorToast = ({ visible, message }: ToastProps) => {
  const cls = cn(
    { 'animation-enter': visible },
    { 'animation-leave': !visible },
    containerClasses,
    'border-toast-error-border bg-toast-error-bg text-toast-error-text',
  );
  return (
    <div className={cls}>
      <div className="p-4 w-3 text-toast-error-icon justify-center">
        <AlertIcon className="fill-error" />
      </div>
      <div className="flex-1 justify-start w-0 p-4">{message}</div>
    </div>
  );
};

const compMap: Record<string, FC<ToastProps>> = {
  success: SuccessToast,
  error: ErrorToast,
};

const Toaster = () => {
  return (
    <HotToaster
      position="bottom-left"
      gutter={10}
      containerClassName="toaster"
      toastOptions={{
        duration: 6000,
        style: {
          borderRadius: 0,
          padding: 0,
          minWidth: '300px',
        },
      }}
    >
      {(t: Toast) => (
        <ToastBar toast={t}>
          {() => {
            const El = compMap[t.type];
            // rendering the message returned by ToastBar centers the text.
            // using directly from the toast is a string.
            return <El message={t.message as string} visible={t.visible} />;
          }}
        </ToastBar>
      )}
    </HotToaster>
  );
};

export default Toaster;
