import { HotkeysProvider } from 'react-hotkeys-hook';
import { PropsWithChildren } from 'react';

// https://react-hotkeys-hook.vercel.app/docs/documentation/hotkeys-provider
// using the provider allows for applying scopes to the hotkeys in the app.
const Provider = ({ children }: PropsWithChildren) => {
  return <HotkeysProvider>{children}</HotkeysProvider>;
};

export default Provider;
