import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

type ConditionalHiddenInputProps = {
  name: string;
  value: string | undefined;
};

const ConditionalHiddenInput = ({
  name,
  value,
}: ConditionalHiddenInputProps) => {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    if (value) {
      setValue(name, value);
    }
  }, [value, setValue, name]);

  if (!value) {
    return null;
  }

  return (
    <input
      {...register(name, { required: true })}
      type="hidden"
      value={value}
    />
  );
};

export default ConditionalHiddenInput;
