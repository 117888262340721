import { ReactNode, PropsWithChildren } from 'react';

type Props = {
  dir?: 'col' | 'row';
  className?: string;
} & PropsWithChildren;

const Container = ({
  children,
  dir = 'row',
  className = '',
}: Props): ReactNode => {
  const dirCls = dir === 'row' ? 'flex-row' : 'flex-col';

  return (
    <div
      data-comp="Container"
      className={`relative flex ${dirCls} grow ${className}`}
    >
      {children}
    </div>
  );
};

export default Container;
