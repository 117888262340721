// import { useLocation, useNavigate } from 'react-router-dom';
import useUrlStore from '@/modules/url-store/hooks/useUrlStore';
import Button from '@mui/joy/Button';
import { LookupSampleQueryStringProps } from '@/modules/transactions/types/queryStringProps.ts';

const MockSampleLookupButton = () => {
  const urlStore = useUrlStore();
  // const location = useLocation();

  const selectedSampleId =
    (urlStore.data as LookupSampleQueryStringProps).selectedSampleId || '';
  const selectedSampleName =
    (urlStore.data as LookupSampleQueryStringProps).selectedSampleName || '';

  // const navigate = useNavigate();
  const onSelectContainer = () => {
    urlStore.api.updateQueryString({
      selectedSampleId: 'id:TestSample',
      selectedSampleName: 'Test Sample',
    });
    // navigate(`${location.pathname}${queryString}`);
  };

  const buttonText = selectedSampleId
    ? `${selectedSampleId}, ${selectedSampleName}`
    : 'Select sample';

  return (
    <Button variant="outlined" onClick={onSelectContainer}>
      {buttonText}
    </Button>
  );
};

export default MockSampleLookupButton;
