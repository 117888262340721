import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import logger from '@utils/Logger.ts';
import './i18n.ts';

(globalThis as unknown as any).logger = logger;

logger.info('Starting Afterburner');

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
