'use client';
// import { useRollbar, ErrorBoundary, ErrorBoundaryProps } from '@rollbar/react';
import logger from '@utils/Logger.ts';

type DefaultErrorFallbackProps = {
  error: Error | null;
  resetError: () => void;
};

const DefaultErrorFallback = (props: DefaultErrorFallbackProps) => {
  // const rollbar = useRollbar();
  const { error } = props;
  logger.error('error', error?.message, error?.stack);

  return <div role="alert">{error?.message}</div>;
};

export default DefaultErrorFallback;
