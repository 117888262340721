import {
  PropsWithChildren,
  DetailedHTMLProps,
  HTMLAttributes,
  createElement,
} from 'react';

type ElementType = 'label' | 'legend';

type Props<E> = {
  htmlFor?: string;
  element?: ElementType;
} & PropsWithChildren &
  DetailedHTMLProps<HTMLAttributes<E>, E>;

function Label<E>({
  children,
  className,
  element = 'label',
  ...rest
}: Props<E>) {
  const innerProps = { ...rest, className: `block pb-2.5 ${className}` };
  const el = createElement(element, innerProps, children);
  return el;
}

export default Label;
