import { Link } from 'react-router-dom';
import logo from '@assets/images/afterburner-logo.png';
import { ReactNode } from 'react';
import UserMenu from '@/components/user-menu/UserMenu.tsx';

export const PORTAL_RIGHT_ID = 'mainNavRightPortal';
export const PORTAL_LEFT_ID = 'mainNavLeftPortal';

type MainNavProps = {
  right?: ReactNode;
  left?: ReactNode;
};

const MainNav = ({ left, right }: MainNavProps) => {
  return (
    <nav
      data-comp="MainNav"
      className="flex flex-row flex-nowrap justify-between items-center h-12 border-b bg-gray-dark"
    >
      <div className="flex flex-row flex-nowrap justify-start gap-4 align-middle items-center content-center w-1/2">
        <div className="block w-12 p-2">
          <Link to="/dashboard/scripts" className="flex flex-row gap-2 w-full">
            <img className="h-7" src={logo} alt="logo" />
          </Link>
        </div>
        {/* portal left will put content immediately to the right of the logo */}
        <div
          id={PORTAL_LEFT_ID}
          className="flex flex-row flex-nowrap justify-start gap-4 align-middle content-center"
        >
          {left}
        </div>
      </div>
      <div className="flex flex-row flex-nowrap justify-end gap-2 align-middle items-center content-center w-1/2 me-2">
        {/* portal right will put content on the far right of the main nav*/}
        <div
          id={PORTAL_RIGHT_ID}
          className="flex flex-row flex-nowrap justify-start gap-2 align-middle content-center"
        >
          {right}
        </div>
        <UserMenu />
      </div>
    </nav>
  );
};

MainNav.defaultProps = {
  left: null,
  right: null,
};

export default MainNav;
