import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LookupContext from './SampleLookupContext';
import {
  SidebarIdentifier,
  DetailsFormProps,
  ObjectLookupQueryStringProps,
} from '../../../types';
import { SllId, SllType } from '@/types/Sll';
import useUrlStore from '@/modules/url-store/hooks/useUrlStore.tsx';

// importing directly due to reloading issue I was seeing when using the UrlStoreProvider
// TODO: Use UrlStore Provider instead
import getQueryStringFromObject from '@/modules/url-store/utils/getQueryStringFromObject';

type SampleLookupProviderProps = {
  children: React.ReactNode;
};

type SelectedItemsProps = {
  selectedItems: string[];
};

export type SelectedItem = {
  id: SllId;
  name: string;
};
const SampleLookupProvider = ({ children }: SampleLookupProviderProps) => {
  const navigate = useNavigate();
  const urlStore = useUrlStore();
  const { identifier = '' } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  // const selectedItemIds = searchParams.getAll('selectedItemIds') || [];
  // const selectedItemNames = searchParams.getAll('selectedItemNames') || [];
  const formPath = searchParams.get('formPath') || '';
  const formQueryString = searchParams.get('formQueryString') || '';
  const searchTerm = searchParams.get('searchTerm') || '';
  // const scriptId = searchParams.get('scriptId') || '';

  // WIP url store conversion
  const selectedItems = ((urlStore.data as SelectedItemsProps)?.selectedItems ||
    []) as unknown as SelectedItem[];

  const [debouncedSearchTerm, setSearchTerm] = useState(searchTerm);

  const baseUrl = '/lookup/sample/';

  const sllId = searchParams.get('sllId') || '';

  // const category = searchParams.get('category') || '';

  const category = 'Object.Sample';

  const sidebarSelection = useMemo(
    () => ({
      identifier: identifier as SidebarIdentifier,
      sllId,
    }),
    [identifier, sllId],
  );

  const formUrl = `${formPath}${formQueryString}`;

  const saveSearchTerm = (searchTerm: string) => {
    console.log('ContainerLookupProvider.setSearchTerm  ', searchTerm);
    urlStore.api?.updateQueryString(
      { searchTerm: searchTerm },
      { replace: true },
    );
  };

  const toggleSelectedItem = (item: { id: SllId; name: string }) => {
    const isSelected = !!selectedItems.find((i) => i.id === item.id);
    // setSearchParams((prev) => {
    if (isSelected) {
      console.log('toggling');
      const newItems = selectedItems.filter((i) => i.id !== item.id);
      console.log('selectedItems: ', selectedItems);
      console.log('item: ', item);
      console.log('newItems: ', newItems);
      urlStore.api?.updateQueryString(
        { selectedItems: newItems },
        { replace: true },
      );
    } else {
      console.log('toggling');
      urlStore.api?.updateQueryString(
        // { selectedItems: ['blah'] },
        { selectedItems: [...selectedItems, item] },
        { replace: true },
      );
    }
    // return prev;
    // });
  };

  const setCategory = (category: SllType) =>
    setSearchParams((prev) => {
      prev.set('category', category);
      return prev;
    });

  const setSidebarSelection = useCallback(
    (identifier: SidebarIdentifier, sllId: SllId = '') => {
      console.log('called');
      setSearchParams((prev) => {
        prev.set('sllId', sllId);
        return prev;
      });
      const url = `${baseUrl}${identifier}/?${searchParams.toString()}`;
      navigate(url);
    },
    [setSearchParams, navigate, searchParams],
  );

  const clearSelectedItem = () => {
    setSearchParams((prev) => {
      prev.delete('selectedItemId');
      prev.delete('selectedItemName');
      return prev;
    });
  };

  const onSubmit = () => {
    const formParams = (
      urlStore.data as ObjectLookupQueryStringProps<DetailsFormProps>
    ).formQueryString;
    const url = `${formPath}?${getQueryStringFromObject({
      ...formParams,
      selectedItems,
    })}`;
    navigate(url);
  };

  const value = {
    selectedItems,
    sidebarSelection,
    category,
    searchTerm,
    debouncedSearchTerm,
    formUrl,
    saveSearchTerm,
    setSearchTerm,
    setCategory,
    setSidebarSelection,
    clearSelectedItem,
    toggleSelectedItem,
    onSubmit,
  };

  return (
    <LookupContext.Provider value={value}>{children}</LookupContext.Provider>
  );
};

export default SampleLookupProvider;
