import ArrowDownIcon from '@/modules/icons/ArrowDownIcon';
import SelectJoy, { SelectProps } from '@mui/joy/Select';
// import { useCallback } from 'react';

export type SelectInputProps = {
  temp?: string;
} & SelectProps<any>;

const Select = ({ variant = 'outlined', ...restProps }: SelectInputProps) => {
  // const onChangeInner = useCallback(
  //   (_event: any, value: string | null) => {
  //     onChange(value);
  //   },
  //   [onChange],
  // );

  return (
    <SelectJoy
      {...restProps}
      indicator={
        <div className="border-l pl-2 flex items-center">
          <ArrowDownIcon />
        </div>
      }
      // {...rest}
      // onChange={onChangeInner}
      variant={variant}
    />
  );
};

export default Select;
