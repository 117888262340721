import { ReactSVG } from 'react-svg';
import { IconType } from './IconList';

type IconDecorationProps = {
  className?: string;
  width?: string;
  height?: string;
};

export type ComposedIconProps = {
  icon?: string;
  svgClasses?: string;
} & IconDecorationProps;

export type IconProps = {
  icon: IconType;
  svgClasses?: string;
} & IconDecorationProps;

const Icon = (props: IconProps) => {
  const { className } = props;
  return (
    <ReactSVG
      beforeInjection={(svg) =>
        svg.setAttribute(
          'style',
          `width: ${props.width || '16px'}; height: ${props.height || '16px'}`,
        )
      }
      width={props.width || '16px'}
      height={props.height || '16px'}
      className={`icon ${className || ''}`}
      //   beforeInjection={(svg) => svg.setAttribute('style', 'fill: blue')}
      src={props.icon}
      // beforeInjection={(svg) => {
      //   svg.classList.add('fill-inherit')
      //   svg.classList.add('stroke-inherit');
      //   if (props.svgClasses) {
      //     svg.classList.add(props.svgClasses);
      //   }
      // }}
    />
  );
};

export default Icon;
