import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Route, Navigate } from 'react-router-dom';
import AuthRoute from '@components/auth-route/AuthRoute';
// TODO: maybe lazy import this?
import ContainerLookupProvider from './modules/container-lookup/providers/ContainerLookupProvider';
import SampleLookupProvider from './modules/sample-lookup/providers/SampleLookupProvider';

const getObjectLookupRoutes = () => {
  const ContainerLookupPage = lazy(
    () => import('./modules/container-lookup/ContainerLookupPage'),
  );
  const SampleLookupPage = lazy(
    () => import('./modules/sample-lookup/SampleLookupPage'),
  );
  const ContainerTable = lazy(
    () => import('./modules/container-lookup/ContainerTable'),
  );
  const CategoryList = lazy(
    () => import('./modules/container-lookup/CategoryList'),
  );
  return (
    <Route
      path="lookup"
      element={
        <Suspense fallback={<div>Loading</div>}>
          <AuthRoute>
            <Outlet />
          </AuthRoute>
        </Suspense>
      }
    >
      <Route
        path="container"
        element={
          <ContainerLookupProvider>
            <Outlet />
          </ContainerLookupProvider>
        }
      >
        <Route path=":identifier" element={<ContainerLookupPage />}>
          <Route path="items" element={<ContainerTable />} />
          <Route path="" index element={<CategoryList />} />
        </Route>
        <Route path="" index element={<Navigate to="all" replace />} />
      </Route>
      <Route
        path="sample"
        element={
          <SampleLookupProvider>
            <Outlet />
          </SampleLookupProvider>
        }
      >
        <Route path=":identifier" element={<SampleLookupPage />} />
        <Route path="" index element={<Navigate to="all" replace />} />
      </Route>
    </Route>
  );
};
export default getObjectLookupRoutes;
