import { Provider } from '@rollbar/react';
import settings from '../Settings';
import { ReactNode } from 'react';

type RollbarProviderProps = {
  children: ReactNode;
};

const RollbarProvider = ({ children }: RollbarProviderProps) => {
  const rollbarConfig = {
    accessToken: settings.rollbarAccessToken,
    environment: settings.environment,
  };
  return <Provider config={rollbarConfig}>{children}</Provider>;
};

export default RollbarProvider;
