import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import AuthRoute from '@components/auth-route/AuthRoute';
import TransactionsOutlet from '../transactions/TransactionsOutlet';

const getShippingRoutes = () => {
  const ShippingLabelPage = lazy(() => import('./ShippingLabelPage.tsx'));

  return (
    <Route path="shipping-labels" element={<TransactionsOutlet />}>
      {/* After they submit the cart it will transition to the /*/}
      <Route
        path=":notebookId/:transactionId"
        index
        element={
          <Suspense fallback={<div>Loading</div>}>
            <AuthRoute>
              <ShippingLabelPage />
            </AuthRoute>
          </Suspense>
        }
      />
    </Route>
  );
};
export default getShippingRoutes;
