import { PropsWithChildren } from 'react';

type SiteContainerProps = {
  className?: string;
  style?: Record<string, string>;
} & PropsWithChildren;

// This will set the max-width to the breakpoint min-width after xl size it hit then center the content.
// Before the xl size is hit the width is 100%;
const MainContainer = ({
  children,
  className,
  ...rest
}: SiteContainerProps) => {
  return (
    <main
      {...rest}
      className={`2xl:container 2xl:mx-auto xl:container xl:mx-auto box-border min-h-screen flex flex-col ${className}`}
    >
      {children}
    </main>
  );
};

export default MainContainer;
