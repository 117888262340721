import { createContext, Context } from 'react';

import { UrlStoreState } from './UrlStoreProvider.tsx';

const UrlStoreContext: Context<UrlStoreState> = createContext({
  api: undefined,
  data: undefined,
} as unknown as UrlStoreState);

export default UrlStoreContext;
