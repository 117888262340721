import { lazy, Suspense } from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import AuthRoute from '@components/auth-route/AuthRoute';
import TransactionsOutlet from './TransactionsOutlet';
import GeneralStepPage from '@modules/transactions/modules/transaction-form/GeneralStepPage.tsx';

const getTransactionsRoutes = () => {
  const TransactionsStart = lazy(() => import('./TransactionStart'));
  const ItemStepPage = lazy(
    () => import('@modules/transactions/modules/transaction-form/ItemStepPage'),
  );

  const TransactionCartDetailsPage = lazy(
    () => import('./modules/transaction-form/CartDetailsPage.tsx'),
  );
  const ConfirmationPage = lazy(
    () => import('./modules/transaction-form/ConfirmationPage.tsx'),
  );

  return (
    <Route path="transactions" element={<TransactionsOutlet />}>
      {/* This will render the intro form for the type of transaction. */}
      <Route path=":transactionType" element={<Outlet />}>
        <Route
          path="general"
          index
          element={
            <Suspense fallback={<div>Loading</div>}>
              <AuthRoute>
                <GeneralStepPage />
              </AuthRoute>
            </Suspense>
          }
        />
        {/* This will render the main form for the item selected */}
        <Route
          path="items"
          element={
            <Suspense fallback={<div>Loading</div>}>
              <AuthRoute>
                <ItemStepPage />
              </AuthRoute>
            </Suspense>
          }
        />
        <Route
          path="details"
          element={
            <Suspense fallback={<div>Loading</div>}>
              <AuthRoute>
                <TransactionCartDetailsPage />
              </AuthRoute>
            </Suspense>
          }
        />
      </Route>
      <Route
        path="start/:transactionType"
        element={
          <Suspense fallback={<div>Loading</div>}>
            <TransactionsStart />
          </Suspense>
        }
      />
      {/* After they submit the cart it will transition to the /*/}
      <Route path=":transactionId" element={<Outlet />}>
        <Route
          path="confirmation"
          element={
            <Suspense fallback={<></>}>
              <AuthRoute>
                <ConfirmationPage />
              </AuthRoute>
            </Suspense>
          }
        />
      </Route>
      <Route
        path=""
        index
        element={<Navigate to="/dashboard/scripts" replace />}
      />
    </Route>
  );
};
export default getTransactionsRoutes;
