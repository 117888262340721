import { AxiosError } from 'axios';

const extractAxiosError = (error: unknown) => {
  const err: string =
    ((error as AxiosError).response?.data as any)?.error || '';
  const msg: string =
    ((error as AxiosError).response?.data as any)?.message || '';
  if (!err) {
    return (error as Error).message;
  }
  return `${err} ${msg}`;
};

export default extractAxiosError;
