import { useHotkeys } from 'react-hotkeys-hook';

const Hotkeys = () => {
  const onClearLocalStorage = () => {
    window.localStorage.clear();
  };

  useHotkeys('ctrl + shift + c', onClearLocalStorage);
  return null;
};

export default Hotkeys;
