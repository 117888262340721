import {
  FormConfiguration,
  FormFactoryConfiguration,
} from '@modules/transactions/modules/form-factory/types.ts';
import ShipmentContainerForm from './modules/forms/shipment-container/ShipmentContainerForm';
import ShipmentSampleEntryForm from './modules/forms/shipment-sample/ShipmentSampleEntryForm';
import { z as zod } from 'zod';
import { FC } from 'react';

export const commonValid = zod.object({
  notebookId: zod.string(),
  siteId: zod.string(),
});

const shipmentContainerValid = zod.object({
  model_id: zod.string(),
  count: zod.number(),
  // TODO: This is an array, but for now just doing a single string.
  label: zod.string().min(3),
  //labels: zod.array(zod.string()).nonempty()
});

const shipmentContainerSubmitValid = commonValid.merge(shipmentContainerValid);

const shipmentContainer: FormConfiguration = {
  name: 'shipment-container',
  component: ShipmentContainerForm as unknown as FC,
  validator: shipmentContainerSubmitValid,
};

const shipmentSample: FormConfiguration = {
  name: 'shipment-sample',
  component: ShipmentSampleEntryForm as unknown as FC,
  validator: null,
};

const shipment: Record<string, FormConfiguration> = {
  container: shipmentContainer,
  sample: shipmentSample,
};

export default {
  shipment,
} as FormFactoryConfiguration;
