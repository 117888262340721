import { PARSING_OPTIONS, JSON_REGEXP } from '../constants.ts';
import qs, { ParsedQuery } from 'query-string';

const parseString = (str: string): Record<string, unknown> | string => {
  const value = decodeURIComponent(str);
  // if the string is a json object parse it otherwise just
  // set the decoded value.
  if (value.match(JSON_REGEXP)) {
    const foundJson = value.match(JSON_REGEXP) as RegExpMatchArray;
    return JSON.parse(foundJson[0]) as Record<string, unknown>;
  } else {
    return value;
  }
};

const getQueryStringParams = (queryString: string): ParsedQuery => {
  const queryStringParams: ParsedQuery = qs.parse(queryString, PARSING_OPTIONS);
  // convert stringified json back to json.
  for (const prop in queryStringParams) {
    const value = queryStringParams[prop];
    if (typeof value === 'string') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryStringParams[prop] = parseString(value);
    } else if (Array.isArray(value)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryStringParams[prop] = value.map((item: unknown) => {
        if (typeof item === 'string') {
          return parseString(item);
        }
        return item;
      });
    }
  }
  return queryStringParams;
};

/*const getQueryStringParams = (queryString: string): ParsedQuery => {
  const queryStringParams: ParsedQuery = qs.parse(queryString, PARSING_OPTIONS);
  // convert stringified json back to json.
  for (const prop in queryStringParams) {
    let value = queryStringParams[prop];
    if (typeof value === 'string') {
      // this isn't necessary. qs does it automatically. Leaving here for reference.
      value = decodeURIComponent(value);
      // if the string is a json object parse it otherwise just
      // set the decoded value.
      if (value.match(JSON_REGEXP)) {
        const foundJson = value.match(JSON_REGEXP) as RegExpMatchArray;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        queryStringParams[prop] = JSON.parse(foundJson[0]) as Record<
          string,
          unknown
        >;
      } else {
        queryStringParams[prop] = value;
      }
    }
  }
  return queryStringParams;
};*/

export default getQueryStringParams;
