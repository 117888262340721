import { TransactionCartItem } from './types.ts';
type ListItemProps = {
  index: number;
  item?: TransactionCartItem | undefined;
  onDelete?: (index: number) => unknown;
  onDuplicate?: (index: number) => unknown;
};
import DeleteIcon from '@modules/icons/DeleteIcon.tsx';
import DuplicateIcon from '@modules/icons/DuplicateIcon.tsx';
import CheckSelectedIcon from '@modules/icons/CheckSelectedIcon.tsx';
import CheckUnselectedIcon from '@modules/icons/CheckUnselectedIcon.tsx';
import { useCallback } from 'react';

const ListItem = ({ index, item, onDuplicate, onDelete }: ListItemProps) => {
  const bgColor = item ? 'bg-gray-dark' : 'bg-gray-darkest';
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      console.log('ListItem.onClick', e.target);
      if (e.target instanceof HTMLButtonElement) {
        const {
          dataset: { func },
        } = e.target;
        if (func === 'delete' && onDelete) {
          onDelete(index);
        } else if (func === 'duplicate' && onDuplicate) {
          onDuplicate(index);
        }
      }
    },
    [onDelete, onDuplicate, index],
  );

  const renderButtons = () => {
    if (!item?.name) {
      return null;
    }
    return (
      <div className="flex flex-row flex-nowrap justify-between gap-2">
        <button
          data-index={index}
          data-id={item.id}
          data-func="duplicate"
          onClick={onClick}
        >
          <DuplicateIcon className="pointer-events-none" />
        </button>
        <button
          data-index={index}
          data-id={item.id}
          data-func="delete"
          onClick={onClick}
        >
          <DeleteIcon className="pointer-events-none" />
        </button>
      </div>
    );
  };

  return (
    <li
      className={`flex flex-nowrap justify-between items-start gap-2 h-auto w-full ${bgColor} text-text-normal p-3`}
    >
      <div>{item?.name ? <CheckSelectedIcon /> : <CheckUnselectedIcon />}</div>
      <div className="grow">
        {index + 1} - {item?.name}
      </div>
      {renderButtons()}
    </li>
  );
};

export default ListItem;
