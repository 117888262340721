import { PropsWithChildren, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

type ValueDependentSectionProps = {
  required: string[];
} & PropsWithChildren;

const ValueDependentSection = ({
  children,
  required,
}: ValueDependentSectionProps) => {
  const { watch } = useFormContext();
  const watchFields: string[] = watch(required);
  const vacantFields: string[] = useMemo(() => {
    return watchFields.filter((field) => field === undefined || field === '');
  }, [watchFields]);

  if (vacantFields.length > 0) {
    return null;
  }

  return children;
};

export default ValueDependentSection;
