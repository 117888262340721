import withFeatureFlagSettings, {
  IFeatureFlagSettings,
} from '@modules/feature-flags/withFeatureFlagsSettings';
import apiConfig from '@/apiconfig.json';

export type EnvironmentVariables = {
  VITE_USER_EMAIL?: string;
  VITE_USER_PASSWORD?: string;
  VITE_USER_NODE_ENV?: string;
  VITE_API_URL_CONSTELLATION: string;
  VITE_APP_NAME: string;
  VITE_LOG_LEVEL: string;
  VITE_ROLLBAR_ACCESS_TOKEN: string;
  VITE_CONSTELLATION_BASE_URL: string;
};

type EnvironmentVariableKey = keyof EnvironmentVariables;

export type DevUser = {
  email: string;
  password: string;
};

export interface IBasicSettings {
  environment: string;
  applicationName: string;
  logLevel: string;
  api: unknown;
  user?: DevUser;
}

export interface IRollbar {
  rollbarAccessToken: string;
}

export interface ISettings
  extends IBasicSettings,
    IRollbar,
    IFeatureFlagSettings {}

const stripTrailingSlash = function (str: string): string {
  if (str.substring(-1) === '/') {
    return str.substring(0, str.length - 1);
  }
  return str;
};

const generateApi = function (
  env: EnvironmentVariables,
  config: Record<string, unknown>,
): Record<string, Record<string, string>> {
  const api: Record<string, Record<string, string>> = Object.assign(
    {},
    config,
  ) as Record<string, Record<string, string>>;
  const envKeys: EnvironmentVariableKey[] = Object.keys(
    env,
  ) as EnvironmentVariableKey[];
  const apiKeyPrefix: string = 'VITE_API_URL_';
  const apiKeys = envKeys.filter((key) => key.includes(apiKeyPrefix));
  apiKeys.forEach((key: EnvironmentVariableKey) => {
    const localKey: string = key.split(apiKeyPrefix).join('').toLowerCase();
    api[localKey].baseUrl = stripTrailingSlash(env[key] as string);
  });
  console.log('Settings.generateApi ', api);
  return api;
};

function settingContructor(env: EnvironmentVariables): IBasicSettings {
  const environment = import.meta.env.MODE || 'production';

  const api = generateApi(env, apiConfig);

  const applicationName: string = import.meta.env.VITE_APP_NAME as string;

  const logLevel: string = import.meta.env.VITE_LOG_LEVEL as string;

  const rollbarAccessToken: string = import.meta.env
    .VITE_ROLLBAR_ACCESS_TOKEN as string;

  let result: IBasicSettings & IRollbar = {
    environment,
    applicationName,
    logLevel,
    api,
    rollbarAccessToken,
  };

  /**
   This is just a convenience for devs. It will populate the login fields if you
   add this props to your .env.local.  They should never be added to .env!
   */
  if (import.meta.env.DEV && env.VITE_USER_EMAIL && env.VITE_USER_PASSWORD) {
    const user = {
      email: env.VITE_USER_EMAIL,
      password: env.VITE_USER_PASSWORD,
    };

    result = Object.assign(result, { user });
  }

  return result;
}

const getSettings: (env: EnvironmentVariables) => ISettings =
  withFeatureFlagSettings<ISettings>(settingContructor);

const settings: ISettings = getSettings(
  import.meta.env as unknown as EnvironmentVariables,
);

export default settings;
