import React, { useEffect, useState, useRef } from 'react';
import AuthContext from './AuthContext';
import api from '@modules/auth/api';
import { useNavigate } from 'react-router-dom';
import logger from '@utils/Logger';
import { useLocation } from 'react-router-dom';

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAuthAvailable, setAuthAvailable] = useState(false);
  const prevIsAuthenticated = useRef(false);
  const location = useLocation();

  const navigate = useNavigate();

  // If the Auth is being read for the first time or if the authentication
  // status changes, then reroute to the appropriate spot.
  useEffect(() => {
    if (isAuthAvailable && prevIsAuthenticated.current !== isAuthenticated) {
      if (isAuthenticated && location.pathname.includes('login')) {
        navigate('/dashboard/scripts');
      } else if (!isAuthenticated) {
        navigate('/login');
      }
      prevIsAuthenticated.current = isAuthenticated;
    }
  }, [
    isAuthenticated,
    isAuthAvailable,
    navigate,
    prevIsAuthenticated,
    location.pathname,
  ]);

  const login = async (
    username: string,
    password: string,
  ): Promise<unknown> => {
    try {
      console.log('AuthProvider.login  ', username, password);
      const result: boolean = await api.login(username, password);
      setAuthenticated(result);
      navigate('/dashboard/scripts');
      return result;
    } catch (error) {
      logger.error(error);
      setAuthenticated(false);
      return error;
    }
  };

  const logout = () => {
    api.logout();
    setAuthenticated(false);
  };

  // This takes care of the intial check for an auth token.
  useEffect(() => {
    if (!isAuthAvailable) {
      const authStatus = api.getAuthStatus();
      setAuthenticated(authStatus);
      setAuthAvailable(true);
      if (authStatus && location.pathname.includes('login')) {
        navigate('/dashboard/scripts');
      }
    }
  }, [
    setAuthenticated,
    isAuthenticated,
    isAuthAvailable,
    setAuthAvailable,
    location.pathname,
    navigate,
  ]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {isAuthAvailable && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
