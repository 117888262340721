import Container from './Container.tsx';
import Content from './Content.tsx';
import Footer from './Footer.tsx';
import Header from './Header.tsx';
import SideNav from './SideNav.tsx';
import ColumnGrid from './ColumnGrid.tsx';

const Layout = {
  Container,
  Content,
  Footer,
  Header,
  SideNav,
  ColumnGrid,
};

export default Layout;
