import ConditionalHiddenInput from '@/modules/forms/components/hidden/ConditionalHiddenInput.tsx';

type HiddenFieldsProps = {
  fields: Record<string, any> | undefined;
};

const HiddenFields = ({ fields }: HiddenFieldsProps) => {
  if (!fields) return null;
  const keys = Object.keys(fields);
  return keys.map((name: string) => {
    return (
      <ConditionalHiddenInput key={name} name={name} value={fields[name]} />
    );
  });
};

export default HiddenFields;
