import { useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '@modules/transactions/components/Layout';
import TransactionsMainNav from '@modules/transactions/components/TransactionsMainNav';
import logger from '@utils/Logger';
import Label from '@modules/forms/components/label/Label';
import Button from '@components/button/Button';
import { FormProvider, useForm } from 'react-hook-form';
import useUrlStore from '@modules/url-store/hooks/useUrlStore';
import { DevTool } from '@hookform/devtools';
import CartSidebar from '@modules/transactions/modules/cart-sidebar/CartSidebar.tsx';
import NotebookSelect from '@/modules/forms/components/notebook-select/NotebookSelect.tsx';
import { DBObject } from '@/modules/ecl-types/DBObject.ts';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { commonValid } from '@modules/transactions/formConfigurations.ts';
// import usePersistantForm from '@/modules/forms/usePersistantForm.tsx';
import useNotebooksAndSitesQuery from '@/modules/queries/useNotebooksAndSitesQuery.tsx';

const FORM_ID = 'generalForm';

const GeneralStepPage = () => {
  const params = useParams();
  const { t } = useTranslation();
  logger.info('Transaction > General', params);
  // const formProps = usePersistantForm('transaction.general', {
  //   resolver: zodResolver(commonValid),
  //   shouldUnregister: false,
  // });
  const formProps = useForm({
    resolver: zodResolver(commonValid),
    shouldUnregister: true,
    mode: 'onChange',
  });

  const {
    setValue,
    handleSubmit,
    getFieldState,
    register,
    formState,
    control,
  } = formProps;

  const navigate = useNavigate();
  const urlStore = useUrlStore();

  const {
    isLoading,
    isError,
    error,
    data: notebookAndSiteData,
  } = useNotebooksAndSitesQuery();

  useEffect(() => {
    if (!isLoading && isError) {
      logger.error(error);
    }
  }, [isLoading, isError, error]);

  useEffect(() => {
    const keys = Object.keys(urlStore.data as Record<string, any>);
    if (keys.length) {
      for (const key in urlStore.data) {
        const field = getFieldState(key);
        if (!field.isTouched) {
          setValue(key, (urlStore.data as any)[key]);
        }
      }
    }
  }, [urlStore.data, setValue, getFieldState]);

  const { isDirty, isValid, touchedFields } = formState;

  console.log(
    'formState isDirty',
    isDirty,
    'isValid',
    isValid,
    'touchedFields',
    touchedFields,
  );

  const onSubmit = useCallback(
    (data: Record<string, any>) => {
      const d = { ...data, emptyItem: true };
      console.log('GeneralStepPage onSubmit ', data);
      if (data && data.notebookId) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        localStorage.setItem('notebookId', data.notebookId);
      } else {
        console.error('no notebookId was found');
      }

      urlStore.api?.updateQueryString(d, { replace: true });
      navigate(
        `/transactions/${
          params.transactionType
        }/items?${urlStore.api?.getQueryStringFromObject(d)}`,
        { replace: false },
      );
    },
    [navigate, urlStore.api, params.transactionType],
  );

  return (
    <FormProvider {...formProps}>
      <Layout.Container dir="col" className="relative">
        <DevTool control={control} />
        <Layout.ColumnGrid>
          <Layout.Container dir="col" className="relative">
            <TransactionsMainNav />
            <Layout.Header>General</Layout.Header>
            <Layout.Content>
              <form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
                <Label<HTMLLabelElement> htmlFor="notebook">
                  {t('transactions.whichNotebookLabel')}
                </Label>
                <NotebookSelect
                  name="notebookId"
                  notebookMap={
                    notebookAndSiteData?.notebooks as Record<string, DBObject[]>
                  }
                />
                {notebookAndSiteData?.sites && (
                  <input
                    type="hidden"
                    {...register('siteId')}
                    value={notebookAndSiteData?.sites[0].id}
                  />
                )}
                {notebookAndSiteData?.sites && (
                  <input
                    type="hidden"
                    {...register('siteName')}
                    value={notebookAndSiteData?.sites[0].name}
                  />
                )}
              </form>
            </Layout.Content>
            <Layout.Footer className="bg-gray-dark">
              <Button
                form={FORM_ID}
                type="submit"
                variant="soft"
                disabled={!isDirty || !isValid}
              >
                {t('transactions.generalSubmitButton')}
              </Button>
            </Layout.Footer>
          </Layout.Container>
          <Layout.Container>
            <CartSidebar />
          </Layout.Container>
        </Layout.ColumnGrid>
      </Layout.Container>
    </FormProvider>
  );
};

export default GeneralStepPage;
