import { createContext } from 'react';
import { SidebarIdentifier } from '../../../types';
import { SllId, SllType } from '@/types/Sll';

interface ContainerLookupContextProps {
  sidebarSelection: {
    identifier: SidebarIdentifier;
    sllId: SllId | null;
  };
  category: SllType;
  searchTerm: string;
  debouncedSearchTerm: string;
  selectedItemId: SllId;
  selectedItemName: string;
  formUrl: string;
  saveSearchTerm: (searchTerm: string) => void;
  setSearchTerm: (searchTerm: string) => void;
  setSidebarSelection: (identifier: SidebarIdentifier, sllId?: SllId) => void;
  setContainerType: (type: SllType | null) => void;
  setSelectedItem: (item: { id: SllId; name: string }) => void;
  clearContainerType: () => void;
  clearSelectedItem: () => void;
  onSubmit: () => void;
}

const defaultValues: ContainerLookupContextProps = {
  sidebarSelection: { identifier: 'recents', sllId: null },
  category: '',
  searchTerm: '',
  debouncedSearchTerm: '',
  selectedItemId: '',
  selectedItemName: '',
  formUrl: '',
  saveSearchTerm: (_searchTerm) => {},
  setSelectedItem: (_item) => {},
  setSearchTerm: (_searchTerm) => {},
  setSidebarSelection: (_identifier, _sllId) => {},
  setContainerType: (_type) => {},
  clearContainerType: () => {},
  clearSelectedItem: () => {},
  onSubmit: () => {},
};

const LookupContext = createContext<ContainerLookupContextProps>(defaultValues);
export default LookupContext;
