import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import { useState } from 'react';
import useUrlStore from '@/modules/url-store/hooks/useUrlStore.tsx';
import { useHotkeys } from 'react-hotkeys-hook';
import { JsonView, allExpanded, darkStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

// TODO: This should only load on develop.  It shouldn't be included in prod.
const StateViewer = () => {
  // configure global hotkeys
  const [open, setOpen] = useState<boolean>(false);
  const store = useUrlStore();
  useHotkeys('ctrl + shift + y', () => setOpen(true));
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 500,
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <div>
          <JsonView
            data={JSON.parse(JSON.stringify(store.data))}
            shouldExpandNode={allExpanded}
            style={darkStyles}
          />
        </div>
      </Sheet>
    </Modal>
  );
};

export default StateViewer;
