import HttpService, { IHttpService } from '@modules/services/HttpService.ts';
import Url from '@utils/Url.ts';
import { DBObject, ClientDBObject } from '@/modules/ecl-types/DBObject.ts';

export type NotebooksAndSitesServiceResponse = {
  error?: string;
  notebooks: Record<string, DBObject[]>;
  sites: ClientDBObject[];
};

export default class NotebooksAndSitesService {
  constructor(private _http: IHttpService = new HttpService()) {}

  get(): Promise<NotebooksAndSitesServiceResponse> {
    const url = Url.constellation()
      .endpoint('notebooksAndSites')
      .get()
      .getUrl();
    return this._http.get<NotebooksAndSitesServiceResponse>(url);
  }
}
