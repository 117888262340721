import Router from './Router';
import DefaultErrorFallback from '@components/errors/DefaultErrorFallback';
import SettingsProvider from '@providers/SettingsProvider';
import RollbarProvider from '@providers/RollbarProvider.tsx';
import { ErrorBoundary } from '@rollbar/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ComponentThemeProvider from './ComponentThemeProvider.tsx';
import HotkeysProvider from './providers/HotkeysProvider.tsx';
import Hotkeys from '@/components/hotkeys/Hotkeys.tsx';

import './theme.css';
import './index.css';

const queryClient = new QueryClient();

export default function App() {
  return (
    <SettingsProvider>
      <RollbarProvider>
        <ErrorBoundary fallbackUI={DefaultErrorFallback}>
          <HotkeysProvider>
            <QueryClientProvider client={queryClient}>
              <ComponentThemeProvider>
                <Hotkeys />
                <Router />
              </ComponentThemeProvider>
            </QueryClientProvider>
          </HotkeysProvider>
        </ErrorBoundary>
      </RollbarProvider>
    </SettingsProvider>
  );
}
