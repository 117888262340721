import isPlainObject from 'lodash/isPlainObject';
import qs from 'query-string';
import encodeObjectForUrl from './encodeObjectForUrl';

const getQueryStringFromObject = (params: Record<string, unknown>): string => {
  const jsonned: any = {};
  for (const key in params) {
    const value: unknown = params[key];
    if (isPlainObject(value)) {
      jsonned[key] = encodeObjectForUrl(value as Record<string, unknown>);
    } else if (Array.isArray(value)) {
      const encodedArray = value.map((item: unknown): unknown => {
        if (isPlainObject(item)) {
          return encodeObjectForUrl(item as Record<string, unknown>);
        }
        return item;
      });
      jsonned[key] = encodedArray;
    } else {
      jsonned[key] = value;
    }
  }
  // eslint-disable-next-line
  return qs.stringify(jsonned, {
    arrayFormat: 'bracket',
    sort: false,
  });
};

export default getQueryStringFromObject;
/* eslint-enable @typescript-eslint/no-unsafe-member-access */
