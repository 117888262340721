import { ReactNode, PropsWithChildren } from 'react';

type Props = PropsWithChildren;

const SideNav = ({ children }: Props): ReactNode => {
  return (
    <div
      data-comp="SideNav"
      className="flex flex-col w-56 border-r border-solid bg-gray-medium text-text-normal"
    >
      {children}
    </div>
  );
};

export default SideNav;
