import List from '@modules/transactions/modules/cart-sidebar/List.tsx';
import { useParams } from 'react-router-dom';
import useFormController from '@modules/transactions/modules/form-controller/useFormController.tsx';
import { PropsWithChildren, useMemo, useCallback } from 'react';
import capitalize from 'lodash/capitalize';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import getItemModelIds from '@/modules/transactions/modules/cart-sidebar/selectors/getItemModelIds.ts';
import useUrlStore from '@modules/url-store/hooks/useUrlStore.tsx';
import { ParsedQuery } from 'query-string';

type CartSidebarProps = PropsWithChildren;

const CartSidebar = ({ children }: CartSidebarProps) => {
  const params = useParams();
  const controller = useFormController();
  const urlStore = useUrlStore();

  const { duplicateItem, deleteItem, itemService, items } = controller;

  /**
   * Retrieve the list of items from the database.
   */
  const getItemsList = useCallback(async () => {
    const data = urlStore.data as ParsedQuery;
    const list: any[] = [];
    if (items || data.emptyItem) {
      const notebookId: string | undefined = data?.notebookId as
        | string
        | undefined;

      if (notebookId) {
        const ids = getItemModelIds(items) || [];
        const response = await itemService
          .addNotebookHeader(notebookId)
          .get(ids);
        // console.log(
        //   'CartSidebar.getItemsList  notebookId',
        //   notebookId,
        //   'data.items',
        //   data.items,
        //   'ids',
        //   ids,
        //   'response',
        //   response,
        // );
        if (response.names) {
          response.names.map((name: string, index: number) => {
            const id = ids[index];
            list.push({ name, id });
          });
        }

        const emptyItem: boolean | undefined = data.emptyItem as unknown as
          | boolean
          | undefined;

        if (emptyItem === true) {
          list.push({ empty: true });
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return list;
  }, [urlStore.data, items, itemService]);

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: itemList,
  }: UseQueryResult<any[], any> = useQuery({
    queryKey: ['sidebarData', items],
    queryFn: () => getItemsList(),
  });

  const renderEmpty = () => {
    return (
      <div className="flex flex-col grow align-middle p-3 content-center justify-center items-center w-full">
        <span>No items added</span>
      </div>
    );
  };

  const content = useMemo(() => {
    if (isLoading) {
      return 'loading';
    }
    if (!isLoading && isError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return error.message;
    }

    if (!isLoading && isSuccess) {
      if (itemList.length === 0) {
        return renderEmpty();
      } else {
        return (
          <>
            <List
              items={itemList || []}
              onDuplicate={duplicateItem}
              onDelete={deleteItem}
            />
            {children && (
              <footer className="absolute bottom-0 p-2 border-t border-line-normal w-full flex justify-end">
                {children}
              </footer>
            )}
          </>
        );
      }
    }
  }, [
    isLoading,
    error,
    isSuccess,
    isError,
    itemList,
    deleteItem,
    children,
    duplicateItem,
  ]);

  return (
    <div className="flex flex-col w-full border-l border-line-normal bg-gray-dark relative">
      <header className="grow-0 h-12 p-3 border-b border-line-normal">{`${capitalize(
        params.transactionType,
      )} contains`}</header>
      {content}
    </div>
  );
};

export default CartSidebar;
