import { lazy, Suspense } from 'react';
import { Route, Outlet } from 'react-router-dom';
import AuthRoute from '@components/auth-route/AuthRoute';
import NavLayout from '@/components/layouts/NavLayout';
import MainNav from '@/components/main-nav/MainNav';
import ScriptsOutlet from './ScriptsOutlet.tsx';

const getScriptRoutes = () => {
  const SelectScriptPage = lazy(
    () => import('./modules/select/SelectScriptPage'),
  );
  const DetailsPage = lazy(() => import('./modules/details/DetailsPage'));
  const ConfirmationPage = lazy(
    () => import('./modules/confirm/ConfirmationPage'),
  );
  return (
    <Route
      path={'/script'}
      element={
        <Suspense fallback={<div>Loading</div>}>
          <AuthRoute>
            <Outlet />
          </AuthRoute>
        </Suspense>
      }
    >
      <Route
        path="select"
        element={
          <NavLayout navbar={<MainNav />}>
            <SelectScriptPage />
          </NavLayout>
        }
      />
      <Route path=":templateId" element={<ScriptsOutlet />}>
        <Route
          index
          element={
            <Suspense fallback={<div>Loading</div>}>
              <DetailsPage />
            </Suspense>
          }
        />
        <Route
          path="confirmation"
          element={
            <Suspense fallback={<div>Loading</div>}>
              <ConfirmationPage />
            </Suspense>
          }
        />
      </Route>
    </Route>
  );
};

export default getScriptRoutes;
