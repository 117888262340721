import { ROARR, Roarr, logLevels, Logger } from 'roarr';
import settings from '../Settings';
import { serializeError, isErrorLike } from 'serialize-error';

const START_TIME = Date.now();

const envLogLevel = (logLevels as Record<string, number>)[settings.logLevel];

// (globalThis as any).ROARR = ROARR;

/*
{
    "context": {
        "application": "ECL Afterburner (Local)",
        "logLevel": 60
    },
    "message": "Logging is available",
    "sequence": "3",
    "time": 1696039447306,
    "version": "2.0.0"
}
 */

type MessageContext = {
  logLevel: number;
  time: number;
  parts: unknown[];
  error?: unknown[];
};

type MessageType = {
  context: MessageContext;
  message: string;
  sequence: string;
  time: number;
};

ROARR.write = (message: string): void => {
  const m: MessageType = JSON.parse(message) as MessageType;
  const { context, time } = m;
  const { logLevel, parts, error } = context;
  if (context.logLevel >= envLogLevel) {
    switch (logLevel) {
      case 10:
        console.trace(`[${time - START_TIME}]`, ...parts);
        break;
      case 20:
        console.debug(`[${time - START_TIME}]`, ...parts);
        break;
      case 30:
        console.info(`[${time - START_TIME}]`, ...parts);
        break;
      case 40:
        console.warn(`[${time - START_TIME}]`, ...parts);
        break;
      case 50:
      case 60:
        console.error(
          `[${time - START_TIME}]`,
          ...parts,
          ...(error as unknown[]),
        );
        break;
    }
  }
};

const globalContext = {
  application: settings.applicationName,
  env: settings.environment,
};

const rtrace: Logger = Roarr.child({
  ...globalContext,
  logLevel: 10,
});

const rdebug: Logger = Roarr.child({
  ...globalContext,
  logLevel: 20,
});

const rinfo: Logger = Roarr.child({
  ...globalContext,
  logLevel: 30,
});

const rwarn: Logger = Roarr.child({
  ...globalContext,
  logLevel: 40,
});

const rerror: Logger = Roarr.child({
  ...globalContext,
  logLevel: 50,
});

const rfatal: Logger = Roarr.child({
  ...globalContext,
  logLevel: 60,
});

const func = (fn: (context: Record<string, unknown>) => void) => {
  return (...parts: unknown[]) => {
    const context: Record<string, any> = { parts };
    // if (parts[0] && typeof parts[0] !== 'string') {
    //   const passedContext: Record<string, any> = parts.shift() as Record<string, any>;
    //   context = {...passedContext, parts}
    // } else {
    //   context.parts = parts;
    // }
    fn(context);
  };
};

const errorFunc = (fn: (context: Record<string, unknown>) => void) => {
  return (...input: unknown[]) => {
    const parts = input.filter((item) => typeof item === 'string');
    console.log('ROARR', ROARR);
    const error = input
      .filter((item) => isErrorLike(item))
      .map((item) => serializeError(item));
    const context: Record<string, any> = {
      parts,
      error,
    };
    fn(context);
  };
};

const trace = func(rtrace);

const log = trace;

const debug = func(rdebug);

const info = func(rinfo);

const warn = func(rwarn);

const error = errorFunc(rerror);

const fatal = errorFunc(rfatal);

const noop = () => null;

const devOnly = (func: unknown) => {
  if (envLogLevel >= 30) {
    return func;
  }
  return noop;
};

const logger = {
  log,
  trace,
  debug,
  info,
  warn,
  error,
  fatal,
  // put the console api on logger.
  clear: console.clear,
  assert: devOnly(console.assert),
  count: console.count,
  dir: devOnly(console.dir),
  group: console.group,
  groupCollapsed: console.groupCollapsed,
  groupEnd: console.groupEnd,
  table: devOnly(console.table),
  time: devOnly(console.time),
  timeEnd: devOnly(console.timeEnd),
  timeLog: devOnly(console.timeLog),
  timeStamp: devOnly(console.timeStamp),
};

export default logger;
