import { useParams } from 'react-router-dom';
import { useMemo, useEffect } from 'react';
import Layout from '@modules/transactions/components/Layout';
import TransactionsMainNav from '@modules/transactions/components/TransactionsMainNav';
import logger from '@utils/Logger';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import useUrlStore from '@modules/url-store/hooks/useUrlStore';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import RadioGroup from '@mui/joy/RadioGroup';
import Radio from '@mui/joy/Radio';
import FormFactory from '@modules/transactions/modules/form-factory/FormFactory.tsx';
import formConfigurations from '@modules/transactions/formConfigurations.ts';
import CartSidebar from '@modules/transactions/modules/cart-sidebar/CartSidebar.tsx';
import { DevTool } from '@hookform/devtools';
import {
  ItemsQueryStringProps,
  TransactionSubtypeQueryStringProps,
} from '@/modules/transactions/types/queryStringProps.ts';

export const SUBMIT_PORTAL_ID = 'ItemSubmitContainer';

const ItemStepPage = () => {
  const params = useParams();
  logger.info('ItemStepPage', params);
  // This takes care of the starting form.
  const entryFormProps = useForm();
  const { watch, setValue } = entryFormProps;
  const urlStore = useUrlStore();
  const watchSubtype: string | undefined = watch('subtype') as
    | string
    | undefined;

  const subtypeUrlValue =
    (urlStore.data as TransactionSubtypeQueryStringProps).subtype || '';

  useEffect(() => {
    const subtype =
      (urlStore.data as TransactionSubtypeQueryStringProps).subtype || '';
    console.log('subtype', subtype);
    setValue('subtype', subtype);
  }, [urlStore.data, setValue]);

  const headerText = useMemo(() => {
    return `Item #${
      (urlStore.data as ItemsQueryStringProps).items
        ? (urlStore.data as ItemsQueryStringProps).items.length + 1
        : 1
    }`;
  }, [urlStore.data]);

  const hiddenFields = useMemo(() => {
    const fields: Record<string, any> = {};
    if (watchSubtype) {
      fields.subtype = watchSubtype;
    }
    return fields;
  }, [watchSubtype]);

  return (
    <>
      <DevTool control={entryFormProps.control} />
      <Layout.Container
        dir="col"
        className="relative text-text-normal justify-stretch"
      >
        <Layout.ColumnGrid>
          <Layout.Container dir="col" className="relative text-text-normal">
            <TransactionsMainNav />
            <Layout.Header>{headerText}</Layout.Header>
            <Layout.Content className="grow">
              {/* TODO: We're going to need a mutable form element here for the different transaction types */}
              <form>
                <FormProvider {...entryFormProps}>
                  <FormControl className="mb-8">
                    <FormLabel className="text-text-normal">
                      What are you shipping?
                    </FormLabel>
                    <Controller
                      control={entryFormProps.control}
                      name="subtype"
                      defaultValue={subtypeUrlValue || ''}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Radio label="Empty Container" value="container" />
                          <Radio
                            label="Sample"
                            value="sample"
                            slotProps={{
                              input: {
                                'aria-describedby': 'SampleHelperText',
                              },
                            }}
                          />
                          <FormHelperText id="SampleHelperText">
                            (powder, liquid or tablet with mass, volume or
                            count)
                          </FormHelperText>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </FormProvider>
              </form>
              {params?.transactionType && watchSubtype && (
                <FormFactory
                  type={params.transactionType}
                  subtype={watchSubtype}
                  config={formConfigurations}
                  hiddenFields={hiddenFields}
                ></FormFactory>
              )}
            </Layout.Content>
            <Layout.Footer className="bg-gray-dark">
              <div id={SUBMIT_PORTAL_ID} />
            </Layout.Footer>
          </Layout.Container>
          <Layout.Container>
            <CartSidebar />
          </Layout.Container>
        </Layout.ColumnGrid>
      </Layout.Container>
    </>
  );
};

export default ItemStepPage;
