/**
 This utility is to be used in conjunction with the apiconfig.json api configuration.  The reason it exists is because the settings
 files for our other projects have tended to get very chaotic over time as people have added new api information and not followed
 a pattern.

 This utility is supposed to help enforce a pattern within the api configuration, and to allow future changes to the api configuration
 without requiring all references to be updated.

 For example if your endpoint is constructed this this:

 // apiconfig.json
 api: {
 curation: {
 user: {
 path: 'https:auth.com/v1/user
 }
 }
 }

 const url = Url.curation().endpoint('user').read().getUrl();

 if at some point you needed a new version of read:

 // apiconfig.json
 api: {
 curation: {
 user: {
 read: 'https:auth.com/v2/user,
 path: 'https:auth.com/v1/user
 }
 }
 }

 The url factory doesn't need to be touched.

 The apiconfig.json can include url templates like this:

 api: {
 curation: {
 user: {
 read: 'https:auth.com/v2/user/:userId',
 }
 }
 }

 To populate the template variable:

 const url = Url.constellation().endpoint('user').read().populate('1234').getUrl();

 There is also a query string utility.  It is not a currying function.  You should use it after getUrl()

 const url = Url.constellation().endpoint('user').read().populate('1234').getUrl().query({test: 1234})

 // result
 const url = https:auth.com/v2/user/1234?test=1234

 */

import template from 'lodash/template';
import ObjectToQueryString from './ObjectToQueryString';
import settings from '@/Settings.ts';

type PathConfig = {
  path?: string;
  get?: string;
  post?: string;
  delete?: string;
  patch?: string;
  put?: string;
};

type DomainConfig = {
  baseUrl: string;
} & Record<string, PathConfig>;

type Method = keyof PathConfig;

export const stripTrailingSlash = (str: string) => {
  let result: string = str;
  if (result.charAt(result.length - 1) === '/') {
    result = result.substring(0, result.length - 1);
  }
  return result;
};

export default class Url {
  private static POST: Method = 'post';
  private static GET: Method = 'get';
  private static PATCH: Method = 'patch';
  private static PUT: Method = 'put';
  private static DELETE: Method = 'delete';

  domain: string;
  domainData: DomainConfig;
  baseUrl: string;
  url: string;
  post: () => Url;
  get: () => Url;
  patch: () => Url;
  put: () => Url;
  del: () => Url;

  private _endpoint: string = '';

  constructor(domain: string, config: Record<string, DomainConfig>) {
    this.domain = domain;
    if (!config[this.domain])
      throw Error(`Api configuration is missing ${domain}`);
    this.domainData = config[this.domain];
    if (!this.domainData.baseUrl)
      throw Error(`Api ${domain} configuration is missing baseUrl`);
    this.baseUrl = stripTrailingSlash(this.domainData?.baseUrl);

    this.url = '';
    this.post = (): Url => {
      return this.method(Url.POST);
    };

    this.get = () => {
      return this.method(Url.GET);
    };

    this.patch = () => {
      return this.method(Url.PATCH);
    };
    this.put = () => {
      return this.method(Url.PUT);
    };
    this.del = () => {
      return this.method(Url.DELETE);
    };
  }

  static buildUrl(domain: string, config: any): Url {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return new Url(domain, config);
  }

  static constellation(config: any = settings.api): Url {
    const url = Url.buildUrl('constellation', config);
    return url;
  }

  endpoint(endpoint: string): Url {
    this._endpoint = endpoint;
    return this;
  }

  method(method: Method = 'path'): Url {
    let meth: Method = method;

    const endpointConfig: Record<string, any> = this.domainData[this._endpoint];

    // default to path
    if (meth !== 'path' && !endpointConfig[meth]) {
      meth = 'path';
    }

    this.url = `${this.baseUrl}${this.domainData[this._endpoint][meth]}`;

    return this;
  }

  populate(data: Record<string, any>): Url {
    const regx = /\${([\S]*?)(?=)}/;
    const rgResult: RegExpMatchArray | null = this.url.match(regx);
    if (!rgResult)
      throw new Error(
        'You are trying to populate a url that is not a template',
      );
    const compiler = template(this.url);
    this.url = compiler(data);
    return this;
  }

  path(): Url {
    return this.method();
  }

  id(id: string | number) {
    this.url += `/${id}`;
    return this;
  }

  ids(arr: string[]) {
    this.url += `/${arr.join(',')}`;
    return this;
  }

  queryString(str: string) {
    return `${this.url}?${str}`;
  }

  // This will just encode a simple "flat" object of parameters.
  query(query: Record<string, any>) {
    return this.queryString(ObjectToQueryString.asSimpleQuery(query));
  }

  getUrl() {
    return this.url;
  }
}
