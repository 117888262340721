import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import AuthRoute from '@components/auth-route/AuthRoute';

const getDashboardRoutes = () => {
  const DashboardPage = lazy(() => import('./DashboardPage'));
  const DashboardOutlet = lazy(() => import('./DashboardOutlet'));
  const ObjectDetailPage = lazy(
    () => import('./modules/object-details/ObjectDetailPage'),
  );

  return (
    <Route
      path={'/'}
      element={
        <Suspense fallback={<div>Loading</div>}>
          <AuthRoute>
            <DashboardOutlet />
          </AuthRoute>
        </Suspense>
      }
    >
      <Route
        path="dashboard/:dashboardType"
        element={<DashboardPage />}
      ></Route>
      <Route path="item/:itemId" element={<ObjectDetailPage />} />
    </Route>
  );
};
export default getDashboardRoutes;
