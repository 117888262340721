import { ModelObject } from 'src/modules/ecl-types/DBObject.ts';
import { EmptyTransactionCartItem } from '../types.ts';

const getItemModelIds = (data: (ModelObject | EmptyTransactionCartItem)[]) => {
  const ids: Set<string> = new Set();
  if (data) {
    const items: (ModelObject | EmptyTransactionCartItem)[] = data;
    // filter out any empty items.
    const filtered: ModelObject[] = items.filter(
      (item: ModelObject | EmptyTransactionCartItem): boolean =>
        !!(item as ModelObject)?.modelId,
    ) as ModelObject[];
    // get the ids from the objects
    const list: string[] = filtered.map(
      (item: ModelObject): string => item.modelId,
    );
    list.forEach((v: string) => ids.add(v));
  }
  return Array.from(ids);
};

export default getItemModelIds;
