import { useQuery, UseQueryResult } from '@tanstack/react-query';
import NotebooksAndSitesService, {
  NotebooksAndSitesServiceResponse,
} from '@/modules/transactions/services/NotebooksAndSitesService.ts';

const service = new NotebooksAndSitesService();

const useNotebooksAndSitesService = (): UseQueryResult<
  NotebooksAndSitesServiceResponse,
  Error
> => {
  const result = useQuery({
    queryKey: ['notebooks'],
    queryFn: () => service.get(),
  });
  return result;
};

export default useNotebooksAndSitesService;
