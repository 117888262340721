import queryString from 'query-string';

interface IObjectToQueryString {
  // asQuery: IAsQuery;
  asSimpleQuery: (data: Record<string, unknown>) => string;
}

const ObjectToQueryString: IObjectToQueryString = {
  // OData query string conversion
  /*asQuery: function(obj: any, prefix: string = null): string {
    return jsonToODataQueryString(obj, prefix);
  },*/

  // non-ODAta query
  asSimpleQuery: function (data: Record<string, unknown>): string {
    const clone: Record<string, unknown> = Object.assign({}, data);
    const keys: string[] = Object.keys(clone);

    // this is dealing with issue of arrays that look like this [""] stringify ignores them.
    keys.forEach((key: string) => {
      const item: unknown = clone[key];
      if (Array.isArray(item) && item.length > 0) {
        clone[key] = item.slice();
      }
    });

    return queryString.stringify(clone);
  },
};

export default ObjectToQueryString;
