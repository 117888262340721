/**
 * Converts object keys to a different case type, to go between ruby and js.
 * @modlue objectCaseChanger
 */
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import forOwn from 'lodash/forOwn';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

/**
 * This does deep object property name change
 * @param caseFunction - camelCase or snakeCase
 * @param obj - The object to convert.
 * @returns The converted object
 */
function toType<T>(caseFunction: (inString: string) => string, obj: any): T {
  const doPart = (part: any): any => {
    let pRes: any;

    if (isArray(part)) {
      pRes = [];
    } else {
      pRes = {};
    }

    forOwn(part, (value: any, key: string) => {
      if (isObject(value) || isArray(value)) {
        pRes[caseFunction(key)] = doPart(value);
      } else {
        pRes[caseFunction(key)] = value;
      }
    });

    return pRes;
  };

  return doPart(obj) as T;
}

export function toCamel(obj: any): any {
  return toType(camelCase, obj);
}

export function toSnake<T>(obj: any): T {
  return toType(snakeCase, obj);
}

export default {
  toCamel,
  toSnake,
};
