import { createPortal } from 'react-dom';
import { useEffect, useState, PropsWithChildren, useMemo } from 'react';

export type PortalIntoProps = {
  targetId: string;
} & PropsWithChildren;

const PortalInto = ({ targetId, children }: PortalIntoProps) => {
  const [isDomReady, setDomReady] = useState(false);
  const content = useMemo(() => children, [children]);

  useEffect(() => {
    if (!isDomReady) {
      setDomReady(true);
    }
  }, [isDomReady, setDomReady]);

  if (!document.getElementById(targetId)) return null;

  return (
    isDomReady &&
    createPortal(content, document.getElementById(targetId) as Element)
  );
};

export default PortalInto;
