import { ReactNode, PropsWithChildren, Children } from 'react';
import cn from 'classnames';

type Props = {
  className?: string;
} & PropsWithChildren;

const Footer = ({ children, className }: Props): ReactNode => {
  if (Children.count(children) === 0) return null;

  return (
    <footer
      className={cn(
        'sticky bottom-0 left-0 right-0 flex flex-row flex-nowrap content-center align-middle justify-end text-text-normal p-2.5 border-t border-solid',
        className,
      )}
    >
      {children}
    </footer>
  );
};

export default Footer;
