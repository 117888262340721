import { ReactNode, PropsWithChildren } from 'react';
import styles from './effects.module.css';

type Props = { className?: string } & PropsWithChildren;

const Content = ({ children, className = '' }: Props): ReactNode => {
  return (
    <div
      data-comp="Content"
      className={`relative flex flex-col grow bg-gray-darkest p-7 text-text-normal ${styles.shadow} ${className}`}
    >
      {children}
    </div>
  );
};

export default Content;
