import { useMemo, useCallback, useState, ReactNode, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { useSearchParams, useLocation, Location } from 'react-router-dom';
import { ParsedQuery } from 'query-string';
import getQueryStringFromObject from './utils/getQueryStringFromObject';
import getQueryStringParamsFunc from './utils/getQueryStringParams';
import UrlStoreContext from './UrlStoreContext.tsx';

export type UrlStoreState = {
  data: ParsedQuery | object;
  api: IUrlStoreApi;
};

type UrlStoreProviderProps = {
  children: ReactNode | ((state: UrlStoreState) => JSX.Element);
};

type UpdateQueryStringOptions = {
  replace?: boolean;
  overwritePrevious?: boolean;
};

interface IUrlStoreApi {
  getQueryStringParams(qs: string): Record<string, any>;
  getQueryStringFromObject(obj: any): string;
  updateQueryString(
    params: Record<string, any>,
    options?: UpdateQueryStringOptions,
  ): void;
  queryString: string;
  location: Location;
  searchParams: URLSearchParams;
  setSearchParams(params: URLSearchParams): void;
}

const StoreProvider = ({ children }: UrlStoreProviderProps) => {
  const history = createBrowserHistory();
  const [data, setData] = useState<any>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const queryString = useMemo(() => {
    return location.search;
  }, [location.search]);

  const getQueryStringParams = useCallback((qs: string): ParsedQuery => {
    return getQueryStringParamsFunc(qs);
  }, []);

  useEffect(() => {
    const d: ParsedQuery = getQueryStringParamsFunc(location.search);
    console.log('UrlStoreProvider setting data ', d);
    setData(d);
  }, [location]);

  const updateQueryString = useCallback(
    (params: Record<string, any>, options?: UpdateQueryStringOptions): void => {
      const searchOptions = options?.replace
        ? { replace: options.replace }
        : {};
      const data = options?.overwritePrevious
        ? params
        : { ...getQueryStringParams(location.search), ...params };
      const str = getQueryStringFromObject(data);
      setSearchParams(str, searchOptions);
    },
    [setSearchParams, getQueryStringParams, location.search],
  );

  const api = useMemo(
    () => ({
      updateQueryString,
      getQueryStringFromObject,
      getQueryStringParams,
      queryString,
      location,
      history,
      setSearchParams,
      searchParams,
    }),
    [
      getQueryStringParams,
      queryString,
      updateQueryString,
      location,
      searchParams,
      setSearchParams,
      history,
    ],
  );

  const store: UrlStoreState = useMemo(
    () => ({
      api,
      data,
    }),
    [api, data],
  );

  return (
    <UrlStoreContext.Provider value={store}>
      {typeof children === 'function' ? children(store) : children}
    </UrlStoreContext.Provider>
  );
};

export default StoreProvider;
