import {
  FormFactoryConfiguration,
  FormConfiguration,
  FormComponentProps,
} from '@modules/transactions/modules/form-factory/types.ts';
import { useForm, FormProvider } from 'react-hook-form';
// import { zodResolver } from '@hookform/resolvers/zod';

type FormFactoryProps = {
  type: string;
  subtype: string;
  config: FormFactoryConfiguration;
  onSubmit?: (data: Record<string, any>) => unknown;
  hiddenFields?: Record<string, any>;
};

const FormFactory = ({
  config,
  type,
  subtype,
  hiddenFields,
}: FormFactoryProps) => {
  if (!config) {
    throw new Error('config is not defined');
  }
  if (!type) {
    throw new Error('type is not defined');
  }
  if (!subtype) {
    throw new Error('subtype is not defined');
  }

  const domain: Record<string, FormConfiguration> = config[type];
  const formConfig: FormConfiguration = domain[subtype];

  console.log(
    'FormFactory formConfig: ',
    formConfig,
    'hiddenFields',
    hiddenFields,
  );

  const { component: Comp, validator, ...rest } = formConfig;

  const compProps: FormComponentProps = {
    ...rest,
    hiddenFields,
    subtype,
    type,
  };

  const formProps = useForm({
    /// resolver: zodResolver(validator),
    mode: 'onTouched',
    shouldUnregister: true,
  });

  return (
    <FormProvider {...formProps}>
      <Comp {...compProps} />
    </FormProvider>
  );
};

export default FormFactory;
