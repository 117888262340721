import { ReactNode, PropsWithChildren } from 'react';
import styles from './effects.module.css';

type Props = PropsWithChildren;

const Header = ({ children }: Props): ReactNode => {
  return (
    <header
      className={`relative text-text-normal font-bold p-3 h-12 border-b border-solid border-line-normal ${styles.shadow}`}
    >
      {children}
    </header>
  );
};

export default Header;
