import axios, { AxiosRequestConfig } from 'axios';
import { getAuthToken } from './modules/auth/api';

function addAuthHeader(options: AxiosRequestConfig = {}) {
  const authToken = getAuthToken();
  if (authToken) {
    options.headers = {
      ...(options.headers || {}),
      Authorization: `Bearer ${authToken}`,
    };
  }
}

export async function webApiCall(options: AxiosRequestConfig) {
  addAuthHeader(options);
  return axios(options);
}

export const apiBaseUrl = import.meta.env.PROD
  ? (import.meta.env.VITE_API_URL_CONSTELLATION as string)
  : '/constellation';
