import { useState, MouseEvent, MouseEventHandler } from 'react';
import Button from '../button/Button.tsx';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TripleDotIcon from '@/modules/icons/TripleDotIcon.tsx';

const UserMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onToggleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  const onLogout: MouseEventHandler = () => {
    window.localStorage.removeItem('authToken');
    navigate('/login?isStatic=true');
  };

  return (
    <div>
      <Button
        id="UserMenuButton"
        aria-controls={open ? 'UserMenu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={onToggleOpen}
        variant="outlined"
      >
        <TripleDotIcon width="16px" height="16px" />
      </Button>
      <Menu
        id="UserMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'UserMenuButton',
        }}
      >
        <MenuItem
          data-location="/transactions/start/shipment"
          onClick={onLogout}
        >
          {t('Log Out')}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
