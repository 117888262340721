import { DBObject } from 'src/modules/ecl-types/DBObject.ts';
import Option from '@mui/joy/Option';
import Select, {
  SelectInputProps,
} from '@/modules/forms/components/select/Select.tsx';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import { useMemo } from 'react';

type NotebooksSelectProps = {
  notebookMap: Record<string, DBObject[]>;
} & SelectInputProps;

const NotebookSelect = ({
  notebookMap,
  name,
  ...rest
}: NotebooksSelectProps) => {
  const options = useMemo(() => {
    if (!notebookMap) return null;
    const sectionKeys = Object.keys(notebookMap);
    return sectionKeys.map((section: string) => {
      const list = notebookMap[section];
      if (!list) return null;
      return (
        <List
          key={section}
          aria-labelledby={`select-group-${name}`}
          sx={{ '--ListItemDecorator-size': '28px' }}
        >
          <ListItem
            id={`select-group-${section}`}
            sticky
            sx={{
              backgroundColor: 'var(--color-gray-dark)',
            }}
          >
            {section}
          </ListItem>
          {list.map((item: DBObject) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </List>
      );
    });
  }, [notebookMap, name]);

  return (
    <Select
      name={name}
      {...rest}
      slotProps={{
        listbox: {
          sx: {
            maxHeight: 300,
            overflow: 'auto',
          },
        },
      }}
    >
      {options}
    </Select>
  );
};

export default NotebookSelect;
