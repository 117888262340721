import settings, { ISettings } from '@/Settings';

import React, { createContext, Context } from 'react';

type SettingsProviderProps = {
  children: React.ReactNode;
};

export const SettingsContext: Context<ISettings> = createContext(settings);
const SettingsProvider = ({ children }: SettingsProviderProps) => {
  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
