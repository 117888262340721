import { SelectProps } from '@mui/joy/Select';
import SelectComponent from '@/components/Select';
import {
  useFormContext,
  Controller,
  UseControllerProps,
} from 'react-hook-form';
import { PropsWithChildren } from 'react';

export type SelectInputProps = {
  name: string;
  defaultValue?: string | number;
  rules?: Record<string, any>;
  sx?: Record<string, string>;
} & SelectProps<any> &
  PropsWithChildren &
  UseControllerProps;

const Select = ({
  name,
  defaultValue,
  children,
  rules,
  variant = 'outlined',
  color = 'neutral',
  ...restProps
}: SelectInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, name, ...rest } }) => {
        // We need to the value that is passed back by joy, so we need a custom
        // on change handler.  You can not use setValue in this handler or the
        // formState will become disjointed.
        const onChangeInner = (_e: any, newValue: any) => {
          onChange(newValue);
        };

        return (
          <SelectComponent
            name={name}
            onChange={onChangeInner}
            {...rest}
            {...restProps}
            variant={variant}
            color={color}
          >
            {children}
          </SelectComponent>
        );
      }}
    />
  );
};

export default Select;
