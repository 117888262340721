import { ParseOptions } from 'query-string';
const PARSING_OPTIONS: ParseOptions = {
  arrayFormat: 'bracket',
  parseNumbers: true,
  parseBooleans: true,
  sort: false,
};

// <<json>>[stringified json]<</json>>
const JSON_REGEXP = /(?<=<<json>>)(.*)(?=<<\/json>>)/g;

export { PARSING_OPTIONS, JSON_REGEXP };
