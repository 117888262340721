import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

type NavLayoutProps = {
  navbar: ReactNode;
  children?: ReactNode;
  contentClassName?: string;
};

const NavLayout = ({ navbar, children, contentClassName }: NavLayoutProps) => {
  return (
    <div
      data-comp="NavLayout"
      className="relative flex flex-1 flex-col flex-nowrap min-w-full"
    >
      <div className="flex-none flex-row h-min">{navbar}</div>
      <div
        data-ctx="NavLayout"
        className={`relative flex grow min-w-full ${contentClassName || ''}`}
      >
        {children || <Outlet key="content" />}
      </div>
    </div>
  );
};

export default NavLayout;
