import { useLocation, useNavigate } from 'react-router-dom';
import useUrlStore from '@/modules/url-store/hooks/useUrlStore';
import Button from '@mui/joy/Button';

export type LookupItemQueryStringProps = {
  selectedItemId: string;
  selectedItemName: string;
};

type Props = {
  subtype: string;
};

const ContainerLookupButton = ({ subtype }: Props) => {
  const urlStore = useUrlStore();
  const location = useLocation();

  const selectedItemId = (urlStore.data as LookupItemQueryStringProps)
    .selectedItemId;
  const selectedItemName = (urlStore.data as LookupItemQueryStringProps)
    .selectedItemName;

  const navigate = useNavigate();
  const onSelectContainer = () => {
    const queryString = urlStore.api?.getQueryStringFromObject({
      formPath: location.pathname,
      formQueryString: `${location.search}&subtype=${subtype}`,
    });
    navigate(`/lookup/container/all?${queryString}`);
  };

  const buttonText = selectedItemId
    ? `${selectedItemId}, ${selectedItemName}`
    : 'Select container';

  return (
    <Button variant="outlined" onClick={onSelectContainer}>
      {buttonText}
    </Button>
  );
};

export default ContainerLookupButton;
