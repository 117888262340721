import { ReactNode, PropsWithChildren } from 'react';

type ColumnGridProps = {
  className?: string;
} & PropsWithChildren;

const ColumnGrid = ({ children, className }: ColumnGridProps): ReactNode => {
  return (
    <div
      data-comp="ColumnGrid"
      className={`grid grid-col-1 md:grid-cols-[auto_320px] h-full ${
        className || ''
      }`}
    >
      {children}
    </div>
  );
};

export default ColumnGrid;
