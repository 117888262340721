import RootLayout from '@components/layouts/RootLayout.tsx';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import getAuthRoutes from '@modules/auth/routes';
import getDashboardRoutes from '@modules/dashboard/routes';
import getTransactionsRoutes from '@modules/transactions/routes';
import getShippingRoutes from '@modules/shipping/routes.tsx';
import getDocsRoutes from '@modules/docs/routes';
import getScriptRoutes from '@modules/scripts/routes';
// import getWorkflowsRoutes from '@/modules/workflows/routes.tsx';
import logger from '@utils/Logger';
import AuthProvider from '@modules/auth/providers/AuthProvider';
// import StoreProvider from '@modules/state/StoreProvider';
import getObjectLookupRoutes from './modules/object-lookup/routes';
import Error404 from './components/errors/Error404';
import AuthRoute from '@components/auth-route/AuthRoute.tsx';
import { Helmet } from 'react-helmet';
import Toaster from '@/modules/notifications/Toaster.tsx';
import UrlStoreProvider from '@modules/url-store/UrlStoreProvider.tsx';
import StateViewer from '@modules/url-store/components/StateViewer.tsx';

const Router = () => {
  logger.info('Router starting');
  return (
    <BrowserRouter>
      <AuthProvider>
        {/*<StoreProvider>*/}
        <Helmet>
          <meta charSet="utf-8" />
          <title>ECL Afterburner</title>
          <link
            rel="canonical"
            href="https://afterburner.emeraldcloudlab.com"
          />
        </Helmet>
        <Toaster />
        <UrlStoreProvider>
          <StateViewer />
          <Routes>
            <Route path="/" element={<RootLayout />}>
              {getDashboardRoutes()}
              {getTransactionsRoutes()}
              {getShippingRoutes()}
              {getObjectLookupRoutes()}
              {getAuthRoutes()}
              {getScriptRoutes()}
              {getDocsRoutes()}
              {/*{getWorkflowsRoutes()}*/}
              {/*<Route*/}
              {/*  path=""*/}
              {/*  index*/}
              {/*  element={<Navigate to="/dashboard/scripts" replace />}*/}
              {/*/>*/}
            </Route>
            <Route
              path=""
              index
              element={
                <AuthRoute>
                  {/*
                    If the user is logged in and hit the index route it should
                    navigate to the scripts dashboard.
                    */}
                  <Navigate to="/dashboard/scripts" replace />
                </AuthRoute>
              }
            />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </UrlStoreProvider>
        {/*</StoreProvider>*/}
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;
