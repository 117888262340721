import { FormComponentProps } from 'src/modules/transactions/modules/form-factory/types.ts';
import { useCallback, BaseSyntheticEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MockSampleLookupButton from '@/modules/transactions/components/MockSampleLookupButton.tsx';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import { NumericFormat } from 'react-number-format';
import Input from '@mui/joy/Input';
import ContainerLookupButton from '@/modules/transactions/components/ContainerLookupButton';
import { useTranslation } from 'react-i18next';
import { DevTool } from '@hookform/devtools';
import useFormController from '@modules/transactions/modules/form-controller/useFormController.tsx';
import useUrlStore from '@/modules/url-store/hooks/useUrlStore.tsx';
import { ParsedQuery } from 'query-string';
import { useMutation } from '@tanstack/react-query';
import type { SubmitHandler, FieldValues } from 'react-hook-form';
import { SUBMIT_PORTAL_ID } from '../../transaction-form/ItemStepPage.tsx';
import {
  ItemPostData,
  ItemPostResponse,
} from '../../../services/ItemService.ts';
import FormError from '@/modules/forms/components/form-error/FormError.tsx';
import PortalInto from '@components/portal-into/PortalInto.tsx';
import Button from '@components/button/Button.tsx';
import { toast } from 'react-hot-toast';
import extractAxiosError from '@utils/extractAxiosError.ts';
import { AxiosError } from 'axios';
import ValueDependentSection from '@/modules/forms/components/value-dependent-section/ValueDependentSection.tsx';
import type {
  LookupSampleQueryStringProps,
  NotebookQueryStringProps,
  LookupContainerQueryStringProps,
} from '@/modules/transactions/types/queryStringProps.ts';
import ConditionalHiddenInput from '@/modules/forms/components/hidden/ConditionalHiddenInput.tsx';
import HiddenFields from '@/modules/forms/components/hidden-fields/HiddenFields.tsx';

const FORM_ID = 'TransactionShipmentSampleForm';

const updateState = function (
  currentState: ParsedQuery | object,
  data: Record<string, any>,
  subtype: string,
): Record<string, any> {
  const newState = !currentState ? {} : { ...currentState };
  if (newState.selectedItemId) {
    delete newState.selectedItemId;
  }
  if (newState.selectedItemName) {
    delete newState.selectedItemName;
  }
  const item: Record<string, any> = {
    ...data,
    subtype,
  };
  if (newState?.items && Array.isArray(newState.items)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newState.items.push(item);
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newState.items = [item];
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  newState.emptyItem = false;
  return newState;
};

const bundlePostData = ({
  count,
  itemId,
  label,
}: Record<string, string | number>): ItemPostData => {
  const model_id = String(itemId);
  return {
    count: Number(count),
    model_id, // TODO: Use the id returned from the object lookup flow after the endpoint is fixed.
    labels: [String(label)],
  };
};

const ShipmentSampleEntryForm = (props: FormComponentProps) => {
  console.log('ShipmentContainerForm', props);
  const params = useParams();
  const { hiddenFields, subtype } = props;
  const formProps = useFormController();
  const navigate = useNavigate();
  const urlStore = useUrlStore();
  const { t } = useTranslation();
  const {
    //setValue,
    handleSubmit,
    //getFieldState,
    register,
    formState,
    control,
    itemService: service,
  } = formProps;

  const sampleName =
    (urlStore.data as LookupSampleQueryStringProps).selectedSampleName || '';
  const sampleId =
    (urlStore.data as LookupSampleQueryStringProps).selectedSampleId || '';
  const containerName =
    (urlStore.data as LookupContainerQueryStringProps).selectedItemName || '';
  const containerId =
    (urlStore.data as LookupContainerQueryStringProps).selectedItemId || '';

  const getSubtypeKey = (type: string): string => {
    switch (type) {
      case 'container':
        return 'containers';
    }
    return type;
  };

  const itemMutation = useMutation({
    mutationFn: async (data: ItemPostData) => {
      const notebookId: string =
        (urlStore.data as NotebookQueryStringProps).notebookId || '';
      console.log(
        'ShipmentContainerForm itemMutation call',
        data,
        'notebookId',
        notebookId,
      );
      try {
        const response = await service
          .addNotebookHeader(notebookId)
          .post(data, getSubtypeKey(subtype));
        return response;
      } catch (error) {
        console.log('error', error);
        toast.error(extractAxiosError(error as AxiosError));
      }
    },
  });

  /**
   * TODO: Move this logic back to the ItemStepPage or maybe into the useFormController
   */
  const onSubmitInner: SubmitHandler<FieldValues> = useCallback(
    (data: Record<string, any>, e?: BaseSyntheticEvent): void => {
      e?.preventDefault();
      const async = async (): Promise<void> => {
        console.log('ItemStepPage.onSubmit', data);
        const itemData = bundlePostData(data);
        let response: ItemPostResponse | undefined;
        try {
          response = await itemMutation.mutateAsync(itemData);
        } catch (error) {
          console.log('error', error);
          toast.error(extractAxiosError(error as AxiosError));
          return;
        }
        console.log('mutation response', response);
        const items = response?.items;
        if (items && items.length > 0) {
          // Add the found model id to the item data before submitting.
          const itemData = { ...data, modelId: items[0].id };
          const newState = updateState(urlStore.data, itemData, subtype);
          urlStore.api?.updateQueryString(newState, {
            replace: true,
            overwritePrevious: true,
          });
          const url = `/transactions/${
            params.transactionType
          }/details?${urlStore.api?.getQueryStringFromObject(newState)}`;
          console.log('ItemStepPage.onSubmit', url);
          navigate(url);
        } else {
          throw new Error('No items were found');
        }
      };
      void async();
    },
    [
      urlStore.api,
      subtype,
      navigate,
      params.transactionType,
      urlStore.data,
      itemMutation,
    ],
  );

  return (
    <form id={FORM_ID} onSubmit={handleSubmit(onSubmitInner)}>
      <DevTool control={control} /> {/* set up the dev tool */}
      <FormControl>
        <FormLabel>{t('transactions.shipmentSample.whatModelLabel')}</FormLabel>
        <MockSampleLookupButton />
      </FormControl>
      <ValueDependentSection required={['sampleId']}>
        <FormControl>
          <FormLabel>
            {t('transactions.shipmentSample.whichContainerLabel')}
          </FormLabel>
          <ContainerLookupButton subtype={subtype} />
        </FormControl>
      </ValueDependentSection>
      <ValueDependentSection required={['itemId']}>
        <FormControl>
          <FormLabel>{t('transactions.howManyContainers')}</FormLabel>
          <div className="flex flex-row flex-nowrap gap-3 align-middle">
            <div className="block w-12 flex-none">
              <Input
                {...register('count')}
                defaultValue={1}
                variant="outlined"
                slotProps={{
                  input: {
                    component: NumericFormat,
                  },
                }}
              />
            </div>
            <div className="flex align-middle grow">
              {t('transactions.emptyContainers')}
            </div>
          </div>
        </FormControl>
        <FormControl>
          <FormLabel>{t('transactions.whatLabel')}</FormLabel>
          <Input
            {...register('label', {
              required: t('transactions.requiredField', {
                fieldName: t('label'),
              }),
            })}
            variant="outlined"
          />
          <FormError name="label" />
        </FormControl>
      </ValueDependentSection>
      <HiddenFields fields={hiddenFields} />
      <ConditionalHiddenInput name="sampleId" value={sampleId} />
      <ConditionalHiddenInput name="sampleName" value={sampleName} />
      <ConditionalHiddenInput name="containerId" value={containerId} />
      <ConditionalHiddenInput name="containerName" value={containerName} />
      <PortalInto targetId={SUBMIT_PORTAL_ID}>
        <Button
          form={FORM_ID}
          type="submit"
          disabled={!formState.isValid || formState.isSubmitting}
          variant="outlined"
        >
          Submit
        </Button>
      </PortalInto>
    </form>
  );
};

export default ShipmentSampleEntryForm;
