import HttpService from '@modules/services/HttpService.ts';
import Url from '@utils/Url.ts';

export type TransactionsSubmitData = {
  ids: string[];
};

export type TransactionsSubmitResponse = {
  error?: string;
  transaction: {
    id: string;
    type: string;
  };
};

const API_KEY = 'transactionsSubmit';

export default class SubmitService {
  private _http: HttpService;

  constructor(http = new HttpService()) {
    this._http = http;
  }

  post(
    data: TransactionsSubmitData,
    headers: any,
  ): Promise<TransactionsSubmitResponse> {
    const url = Url.constellation().endpoint(API_KEY).post().getUrl();
    return this._http.post<TransactionsSubmitResponse>(url, data, { headers });
  }
}
