import { EnvironmentVariables, IBasicSettings } from '@/Settings.ts';

export type FeatureFlagsConfig = {
  localStorageKey: string;
  flags: string[];
};

export interface IFeatureFlagSettings {
  featureFlags: FeatureFlagsConfig;
}

export type EnvFeatureFlagProps = {
  VITE_FEATURE_FLAG_LOCAL_STORAGE_KEY?: string;
  VITE_FEATURE_FLAGS?: string;
};

function addFeatureFlagSettings<T>(
  func: (env: EnvironmentVariables) => IBasicSettings,
): (env: EnvironmentVariables) => T {
  return (env: EnvironmentVariables & EnvFeatureFlagProps): T => {
    const { VITE_FEATURE_FLAG_LOCAL_STORAGE_KEY, VITE_FEATURE_FLAGS } = env;

    try {
      const flags: string[] = VITE_FEATURE_FLAGS
        ? VITE_FEATURE_FLAGS.split(',')
        : [];
      const localStorageKey: string =
        VITE_FEATURE_FLAG_LOCAL_STORAGE_KEY || 'featureFlags';

      const featureFlags: FeatureFlagsConfig = {
        localStorageKey,
        flags,
      };

      const settings: T = Object.assign(
        {
          featureFlags,
        },
        func(env),
      ) as T;

      return settings;
    } catch (e) {
      console.error('withFeatureFlagSettings ', e);
      throw e;
    }
  };
}
export default addFeatureFlagSettings;
