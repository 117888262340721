import { createContext } from 'react';
import { SidebarIdentifier } from '../../../types';
import { SllId, SllType } from '@/types/Sll';
import { SelectedItem } from './SampleLookupProvider';

interface SampleLookupContextProps {
  sidebarSelection: {
    identifier: SidebarIdentifier;
    sllId: SllId | null;
  };
  category: SllType;
  searchTerm: string;
  debouncedSearchTerm: string;
  selectedItems: SelectedItem[];
  // selectedItemIds: SllId[];
  // selectedItemNames: string[];
  formUrl: string;
  saveSearchTerm: (searchTerm: string) => void;
  setSearchTerm: (searchTerm: string) => void;
  setSidebarSelection: (identifier: SidebarIdentifier, sllId?: SllId) => void;
  toggleSelectedItem: (item: { id: SllId; name: string }) => void;
  clearSelectedItem: () => void;
  onSubmit: () => void;
}

const defaultValues: SampleLookupContextProps = {
  sidebarSelection: { identifier: 'recents', sllId: null },
  category: '',
  searchTerm: '',
  debouncedSearchTerm: '',
  selectedItems: [],
  // selectedItemNames: [],
  formUrl: '',
  saveSearchTerm: (_searchTerm) => {},
  toggleSelectedItem: (_item) => {},
  setSearchTerm: (_searchTerm) => {},
  setSidebarSelection: (_identifier, _sllId) => {},
  clearSelectedItem: () => {},
  onSubmit: () => {},
};

const SampleLookupContext =
  createContext<SampleLookupContextProps>(defaultValues);
export default SampleLookupContext;
