import { Navigate } from 'react-router-dom';
import { ReactNode } from 'react';
import useAuth from '@modules/auth/hooks/useAuth';

// TODO: This was ported over but has not been fully tested.

type AuthRouteProps = {
  children: ReactNode;
};

/**
 * AuthRoute redirects to the login page when the user is not logged in.
 */
const AuthRoute = ({ children }: AuthRouteProps) => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Navigate replace to="/login" />;
  }
  return children;
};

export default AuthRoute;
