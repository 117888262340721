import { webApiCall } from '@/webApi';
import { AxiosError } from 'axios';
import i18n from '@/i18n';
import AuthError from '../../errors/AuthError';
import Url from '@utils/Url';

const AUTH_TOKEN_KEY = 'authToken';

const AUTH_ERRORS = {
  status401: i18n.t('errors.status401'),
  // TODO: hook this up
  // Timeout: 'Bad network connection. Please try again.',
  // default: 'Failed to connect to ECL. Please try again.',
};

function getAuthErrorMessage(error: AxiosError) {
  let errorText = 'Unknown error';
  if (error.response?.status === 401) {
    errorText = AUTH_ERRORS[`status${error.response.status}`];
  }
  return errorText;
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );
  return JSON.parse(jsonPayload) as { exp: number };
}

function setAuthToken(authToken: string) {
  localStorage.setItem(AUTH_TOKEN_KEY, authToken);
  return authToken;
}

export function getAuthToken() {
  const authToken = localStorage.getItem(AUTH_TOKEN_KEY);
  return authToken;
}

export function getAuthStatus() {
  const authTokenOld = getAuthToken();
  if (authTokenOld) {
    try {
      const { exp } = parseJwt(authTokenOld);
      // Check (locally) if the token is expired
      if (new Date() > new Date(exp * 1000)) {
        setAuthToken('');
        return false;
      }
      setAuthToken(authTokenOld);
      return true;
    } catch (err) {
      return false;
    }
  }
  return false;
}

type LoginApiResponse = {
  AuthToken?: string;
};

export async function login(
  username: string,
  password: string,
): Promise<boolean> {
  console.log('login auth');
  const url = Url.constellation().endpoint('login').post().getUrl();
  console.log('url: ', url);

  // const url = `${apiBaseUrl}/ise/signintoken`;

  const data = {
    username: encodeURIComponent(username),
    password: encodeURIComponent(password),
  };

  const requestOptions = {
    url,
    data,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const responseRaw = await webApiCall(requestOptions);
    const response = (await responseRaw.data) as LoginApiResponse;
    const authToken = response.AuthToken;
    if (!authToken) {
      const responseString = JSON.stringify(response);
      const errorMessage = `Error: Could not log in to Constellation - ${responseString}`;
      const error: AuthError = new AuthError(errorMessage) as Error;
      throw error;
    }
    setAuthToken(authToken);
  } catch (err) {
    throw new AuthError(getAuthErrorMessage(err as AxiosError));
  }
  return true;
}

export function logout() {
  setAuthToken('');
}

export default {
  getAuthToken,
  getAuthStatus,
  login,
  logout,
};
