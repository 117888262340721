import { checkboxClasses, optionClasses } from '@mui/joy';
import {
  CssVarsProvider as JoyCssVarsProvider,
  extendTheme,
} from '@mui/joy/styles';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { PropsWithChildren } from 'react';

// TODO: When the @mui components are that we need to use are added to Joy we can
//  remove the @mui dependency.

// Theming Joy components
const theme = extendTheme({
  cssVarPrefix: 'ab',
  colorSchemes: {
    dark: {
      palette: {
        primary: {
          // Adjust the global variant tokens as you'd like.
          // The tokens should be the same for all color schemes.
          solidBg: 'var(--color-black)',
          solidActiveBg: 'var(--color-orange)',
          outlinedBorder: 'var(--color-blue-light)',
          outlinedColor: 'var(--color-white)',
          outlinedBg: 'var(--color-gray-dark)',
          outlinedActiveBg: 'var(--color-gray-darkest)',
          outlinedHoverBg: 'var(--color-gray-darkest)',
          softColor: 'var(--color-white)',
          softBg: 'var(--color-black)',
          softHoverBg: 'var(--color-orange)',
          softActiveColor: 'var(--color-white)',
          softActiveBg: 'var(--color-orange)',
          plainColor: 'var(--joy-palette-secondary-700)',
          plainActiveBg: 'var(--joy-palette-secondary-100)',
        },
        neutral: {
          solidBg: 'var(--color-black)',
          solidActiveBg: 'var(--color-orange)',
          outlinedBorder: 'var(--color-line-normal)',
          outlinedColor: 'var(--color-white)',
          outlinedBg: 'var(--color-orange)',
          outlinedHoverColor: 'var(--color-white)',
          outlinedHoverBg: 'var(--color-blue-darkest)',
          outlinedActiveBg: 'var(--color-gray-darkest)',
          outlinedHoverBorder: 'var(--color-orange)',
          softColor: 'var(--color-white)',
          softBg: 'var(--color-black)',
          softHoverBg: 'var(--color-orange)',
          softActiveColor: 'var(--color-white)',
          softActiveBg: 'var(--color-orange)',
          plainColor: 'var(--color-white)',
          plainHoverColor: 'var(--color-white)',
          plainActiveBg: 'var(--color-blue-darkest)',
        },
        background: {
          surface: 'var(--color-gray-darkest)',
        },
        danger: {
          outlinedColor: 'var(--color-text-normal)',
          outlinedActiveBorder: 'var(--color-orange)',
        },
      },
    },
  },
  // typography: {
  //   h1: {
  //
  //   }
  // }
  components: {
    JoyCheckbox: {
      styleOverrides: {
        root: {
          color: 'var(--color-gray-lightest)',
          [`&.${checkboxClasses.checked}`]: {
            color: 'var(--color-white)',
          },
        },
        checkbox: () => {
          return {
            backgroundColor: 'var(--color-gray-darkest)',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: 'var(--color-gray-darkest)',
              borderColor: 'var(--color-gray-lightest)',
            },
          };
        },
      },
    },
    JoyFormLabel: {
      styleOverrides: {
        root: () => {
          return {
            color: 'var(--color-text-normal)',
            fontSize: 'var(--ab-font-size-medium)',
            lineHeight: 'var(--ab-font-size-medium)',
          };
        },
      },
    },
    JoyRadioGroup: {
      styleOverrides: {
        root: () => {
          return {
            color: 'var(--color-text-normal)',
            fontSize: 'var(--ab-font-size-medium)',
            lineHeight: 'var(--ab-font-size-medium)',
            gap: 'var(--RadioGroup-gap)',
          };
        },
      },
    },
    JoyRadio: {
      defaultProps: {
        variant: 'outlined',
        size: 'md',
      },
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.variant === 'outlined') {
            return {
              color: 'var(--color-text-normal)',
              fontSize: 'var(--ab-font-size-medium)',
              lineHeight: 'var(--ab-font-size-medium)',
              alignItems: 'center',
            };
          }
        },
        icon: ({ ownerState }) => {
          if (ownerState.variant === 'outlined') {
            return {
              width: 'calc(var(--Radio-size) / 2.3)',
              height: 'calc(var(--Radio-size) / 2.3)',
            };
          }
        },
        radio: ({ ownerState }) => {
          if (ownerState.variant === 'outlined') {
            return {
              color: 'var(--color-text-normal)',
              fontSize: 'var(--ab-font-size-medium)',
              lineHeight: 'var(--ab-font-size-medium)',
              background: 'var(--color-black)',
              '&:hover': {
                background: 'var(--color-black)',
                borderColor: 'var(--color-gray-lightest)',
              },
            };
          }
        },
      },
    },
    JoyInput: {
      defaultProps: {
        variant: 'outlined',
        color: 'neutral',
      },
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.variant === 'outlined') {
            return {
              color: 'var(--color-text-normal)',
              background: 'var(--color-gray-darkest)',
              borderColor: 'var(--color-line-normal)',
              borderRadius: '2px',
            };
          }
        },
      },
    },
    JoySelect: {
      defaultProps: {
        color: 'neutral',
      },
      styleOverrides: {
        root: () => {
          return {
            color: 'var(--color-text-normal)',
            background: 'var(--color-gray-darkest)',
            borderColor: 'var(--color-line-normal)',
            borderRadius: '2px',
            boxShadow: 'none',
            paddingTop: 0,
            paddingBottom: 0,
            alignItems: 'stretch',
          };
        },
        indicator: () => {
          return {
            alignItems: 'stretch',
          };
        },
        listbox: ({ ownerState }) => {
          if (ownerState.variant === 'outlined') {
            return {
              paddingTop: 0,
              paddingBottom: 0,
              marginTop: '-4px',
              border: 'var(--color-blue-light) 1px solid',
              borderRadius: 0,
            };
          }
        },
      },
    },
    JoyOption: {
      styleOverrides: {
        root: () => {
          return {
            backgroundColor: 'var(--color-gray-darkest)',
            [`&:not(.${optionClasses.selected})`]: {
              '&:hover': {
                backgroundColor: 'var(--color-gray-medium)',
              },
            },
            [`&.${optionClasses.selected}`]: {
              backgroundColor: 'var(--color-blue-darkest)',
              '&:hover': {
                backgroundColor: 'var(--color-gray-medium)',
              },
            },
          };
        },
      },
    },
    JoyFormControl: {
      styleOverrides: {
        root: {
          marginBottom: '36px',
        },
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.variant === 'outlined') {
            return {
              color: 'var(--color-gray-lightest)',
              borderColor: 'var(--color-line-normal)',
              borderRadius: '3px',
              backgroundColor: 'var(--color-gray-dark)',
              padding: '3px 10px',
              '&:hover': {
                borderColor: 'var(--color-gray-lightest)',
                color: 'var(--color-white)',
              },
            };
          } else if (ownerState.variant === 'solid') {
            return {
              color: 'var(--color-white)',
              background: 'var(--color-blue-light)',
              borderColor: 'none',
              borderRadius: '3px',
              '&:hover': {
                borderColor: 'none',
                background: 'var(--color-blue-medium)',
                color: 'var(--color-white)',
              },
            };
          } else if (ownerState.variant === 'soft') {
            return {
              color: 'var(--color-white)',
              background: 'var(--color-black)',
              borderColor: 'none',
              borderRadius: '3px',
              '&:hover': {
                borderColor: 'none',
                background: 'black',
                color: 'var(--color-white)',
              },
            };
          } else if (ownerState.variant === 'plain') {
            return {
              color: 'var(--color-blue-lightest)',
              fontWeight: 'normal',
              fontSize: '16px',
              borderColor: 'none',
              borderRadius: '3px',
              padding: '4px',
              '&:hover': {
                borderColor: 'none',
                background: 'none',
                color: 'var(--color-blue-light)',
              },
            };
          }
        },
      },
    },
  },
});

const ComponentThemeProvider = ({ children }: PropsWithChildren) => {
  const materialTheme = materialExtendTheme();
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider defaultMode="dark" theme={theme}>
        {children}
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
};

export default ComponentThemeProvider;
