import { Outlet } from 'react-router-dom';
import MainContainer from '@components/main-container/MainContainer';

const RootLayout = () => {
  return (
    <MainContainer className="text-white">
      <Outlet key="main-outlet" />
    </MainContainer>
  );
};

export default RootLayout;
