import HttpService from '@modules/services/HttpService.ts';

export interface INotebookService<T> {
  addNotebookHeader(notebookId: string): T;
}

export default abstract class AbstractNotebookService<T>
  implements INotebookService<T>
{
  constructor(protected _http = new HttpService()) {}

  addNotebookHeader(notebookId: string): T {
    this._http.addTempHeader('X-ECL-NotebookId', notebookId);
    return this as unknown as T;
  }
}
