import {
  // PropsWithChildren,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { EmptyTransactionCartItem } from '@modules/transactions/modules/cart-sidebar/types.ts';
import useUrlStore from '@modules/url-store/hooks/useUrlStore.tsx';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import logger from '@utils/Logger.ts';
import { useMutation } from '@tanstack/react-query';
import SubmitService, {
  TransactionsSubmitData,
  TransactionsSubmitResponse,
} from '@/modules/transactions/services/SubmitService.ts';
import { ParsedQuery } from 'query-string';
import ItemService from '../../services/ItemService.ts';
import { ModelObject } from '@/modules/ecl-types/DBObject.ts';
import toast from '@modules/notifications/toast.ts';
import extractAxiosError from '@utils/extractAxiosError.ts';
import { useTranslation } from 'react-i18next';
import type {
  NotebookQueryStringProps,
  ItemsQueryStringProps,
} from '@/modules/transactions/types/queryStringProps.ts';

const itemService = new ItemService();
const submitService = new SubmitService();

const useFormController = () => {
  const params = useParams();
  const { t } = useTranslation();
  const formContext = useFormContext();
  const navigate = useNavigate();
  const [submitText, setSubmitText] = useState('Submit');
  const urlStore = useUrlStore();
  const [items, setItems] = useState<
    (ModelObject | EmptyTransactionCartItem)[]
  >([]);
  const [transactionType, setTransactionType] = useState<string>('');

  const finalizeMutation = useMutation({
    mutationFn: async (data: TransactionsSubmitData) => {
      console.log('ShipmentContainerForm itemMutation call', data);
      const notebookId = (urlStore.data as NotebookQueryStringProps).notebookId;
      const response: TransactionsSubmitResponse = await submitService.post(
        data,
        { 'X-ECL-NotebookId': notebookId },
      );
      return response;
    },
  });

  /*
  API
   */

  // TODO: This is broken at the moment.
  const deleteItem = useCallback(
    (index: number) => {
      logger.debug('useFormController.deleteItem', index);
      const data = urlStore.data as ItemsQueryStringProps;
      if (data.items && data.items.at(index)) {
        const newState = { ...data };
        const newItems = Array.from(data.items);
        newItems.splice(index, 1);
        newState.items = newItems;
        const url = `/transactions/${
          params.transactionType
        }/details?${urlStore.api?.getQueryStringFromObject(newState)}`;
        // urlStore.api?.updateQueryString(newState);
        navigate(url);
      }
    },
    [navigate, params.transactionType, urlStore],
  );

  // TODO: This is going to need to create a new container item.
  const duplicateItem = useCallback(
    (index: number) => {
      logger.debug('useFormController.duplicateItem', index);
      const data = urlStore.data as ItemsQueryStringProps;
      if (data?.items && data.items.at(index)) {
        const itemToDup: any = data.items.at(index);
        console.log('itemToDuplicate', itemToDup);
        if (!itemToDup) {
          throw new Error('Item to duplicate could not be found');
        }
        const newState = { ...data };
        const newItems: any[] = [...data.items];
        newItems.push(itemToDup);
        newState.items = newItems;
        const url = `/transactions/${
          params.transactionType
        }/details?${urlStore.api?.getQueryStringFromObject(newState)}`;
        // urlStore.api?.updateQueryString(newState);
        navigate(url);
      }
    },
    [navigate, params.transactionType, urlStore],
  );

  const addItem = useCallback(() => {
    urlStore.api?.updateQueryString({ emptyItem: true });
  }, [urlStore.api]);

  const addEmptyItem = useCallback(() => {
    const { data } = urlStore;
    console.log('addEmptyItem previous data', data);
    const newState: any = { ...data, emptyItem: true };
    console.log('addEmptyItem new data', newState);
    // remove the subtype key or it will be selected on the items page.
    delete newState.subtype;
    const url = `/transactions/${
      params.transactionType
    }/items?${urlStore.api?.getQueryStringFromObject(newState)}`;
    logger.info('useFormController.addEmptyItem', url);
    navigate(url);
  }, [urlStore, params, navigate]);

  const removeEmptyItem = useCallback(() => {
    urlStore.api?.updateQueryString({ emptyItem: false });
  }, [urlStore.api]);

  /**
   * Submit the final transaction to the database.
   */
  const submitTransaction = useCallback(async () => {
    const data: ParsedQuery = urlStore.data as ParsedQuery;
    const notebookId = (data as NotebookQueryStringProps).notebookId;
    if (!notebookId) {
      throw new Error('No notebookId is available');
    }
    const items: any[] = (data && (data?.items as any[])) || [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const ids = items.map((item) => item?.modelId);
    if (ids.length !== items.length) {
      console.error('There is a cart mismatch', items, ids);
    }
    if (ids.length === 0) {
      toast.error(t('errors.emptyCart'));
      return;
    }
    const payload: TransactionsSubmitData = { ids };
    try {
      const response = await finalizeMutation.mutateAsync(payload);
      const {
        transaction: { id: transactionId },
      } = response;
      // navigat to the shipping label and tell it to redirect to the confirmation page.
      navigate(
        `/shipping-labels/${notebookId}/${transactionId}?redirect=${`/transactions/${transactionId}/confirmation`}`,
      );
    } catch (error: any) {
      console.error(error);
      toast.error(extractAxiosError(error));
    }
  }, [navigate, finalizeMutation, urlStore.data, t]);

  /*
  Parse items data into
   */
  // TODO: Figure out how to lock this down with types.
  useEffect(() => {
    const data = urlStore.data as ItemsQueryStringProps;
    if (data) {
      if (data.items || data.emptyItem) {
        const items = data.items;
        const emptyItem: boolean | undefined = data.emptyItem as unknown as
          | boolean
          | undefined;

        const list: any[] = Array.isArray(items) ? [...items] : [];
        if (emptyItem) {
          list.push({ empty: true });
        }
        setItems(list);
      } else {
        setItems([]);
      }
    }
  }, [urlStore.data]);

  useEffect(() => {
    if (params && params?.transactionType !== transactionType) {
      setTransactionType(params.transactionType || '');
    }
  }, [params, transactionType]);

  const api = useMemo(() => {
    return {
      setSubmitText,
      transactionType,
      submitText,
      items,
      addItem,
      deleteItem,
      duplicateItem,
      addEmptyItem,
      removeEmptyItem,
      submitTransaction,
      itemService,
      ...formContext,
    };
  }, [
    formContext,
    setSubmitText,
    transactionType,
    submitText,
    items,
    addItem,
    deleteItem,
    duplicateItem,
    addEmptyItem,
    removeEmptyItem,
    submitTransaction,
  ]);

  return api;
};

export default useFormController;
