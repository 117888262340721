import AbstractNotebookService from '@/modules/services/AbstractNotebookService.ts';
import Url from '@utils/Url.ts';
import { ClientDBObject } from '@/modules/ecl-types/DBObject.ts';

export type ItemPostData = {
  count: number;
  labels: string[];
  model_id: string;
};

export type ItemResponse = {
  error: string;
  names: string[];
};

export type ItemPostResponse = {
  error?: string;
  items: ClientDBObject[];
};

interface IItemService {
  get(ids: string[]): Promise<ItemResponse>;
  post(
    data: ItemPostData,
    transactionType: string,
    headers?: any,
  ): Promise<ItemPostResponse>;
}

const API_KEY = 'transactionsItem';

export default class ItemService extends AbstractNotebookService<IItemService> {
  addNotebookHeader(notebookId: string): IItemService {
    this._http.addTempHeader('X-ECL-NotebookId', notebookId);
    return this;
  }

  get(ids: string[]): Promise<ItemResponse> {
    const url = Url.constellation().endpoint(API_KEY).get().query({ ids });
    return this._http.get<ItemResponse>(url);
  }

  post(
    data: ItemPostData,
    transactionType: string,
    headers: any = {},
  ): Promise<ItemPostResponse> {
    const url = Url.constellation()
      .endpoint(API_KEY)
      .post()
      .populate({ transactionType })
      .getUrl();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return this._http.post<ItemPostResponse>(url, data, headers);
  }
}
