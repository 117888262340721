import { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';
import ShipmentIcon from '@modules/icons/ShipmentIcon.tsx';

import { PORTAL_LEFT_ID } from '@components/main-nav/MainNav.tsx';

const TransactionsMainNav = () => {
  const params = useParams();
  const decoration = useMemo(() => {
    switch (params.transactionType) {
      case 'shipment':
        return (
          <div className="flex flex-nowrap gap-2 align-middle items-center font-bold text-gray-lightest">
            <ShipmentIcon />
            <span className="font-bold">Ship to ECL</span>
          </div>
        );
      default:
        return <></>;
    }
  }, [params.transactionType]);
  const domEl = document.getElementById(PORTAL_LEFT_ID);
  if (!domEl) {
    return null;
  }
  return createPortal(decoration, domEl);
};

export default TransactionsMainNav;
