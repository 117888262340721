import { TransactionCartItem } from './types.ts';
import ListItem from './ListItem.tsx';

type ListProps = {
  items: TransactionCartItem[];
  onDelete?: (index: number) => unknown;
  onDuplicate?: (index: number) => unknown;
};

const List = (props: ListProps) => {
  const { items, onDelete, onDuplicate } = props;
  return (
    <ul className="divide-y divide-line-normal">
      {items.map((item, index: number) => {
        return (
          <ListItem
            key={item.id || index}
            item={item}
            index={index}
            onDelete={onDelete}
            onDuplicate={onDuplicate}
          />
        );
      })}
    </ul>
  );
};

export default List;
