import { createContext } from 'react';

interface AuthContextProps {
  isAuthenticated: boolean;
  login: (username: string, password: string) => Promise<unknown>;
  logout: () => void;
}

const defaultValues: AuthContextProps = {
  isAuthenticated: false,
  login: async (_username, _password) => Promise.resolve(false),
  logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(defaultValues);
export default AuthContext;
