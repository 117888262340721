import { ErrorMessage } from '@hookform/error-message';
import { useFormContext, get } from 'react-hook-form';
import { PropsWithChildren } from 'react';

type FormErrorProps = {
  name: string;
  message?: string;
} & PropsWithChildren;

const FormError = (props: FormErrorProps) => {
  const { name, message } = props;
  const methods = useFormContext();
  const error = get(methods.formState.errors, name);
  if (!error) return null;
  const { message: messageFromRegister } = error;

  console.log('FormError', error);

  return (
    <ErrorMessage
      as={'div'}
      name={name}
      message={messageFromRegister || message}
      className="block w-full p-1 my-1 text-error border border-error-border bg-error-bg"
    />
  );
};

export default FormError;
