import { Route } from 'react-router-dom';
// TODO: Ideally this shouldn't part of the app at all.
import { lazy, Suspense } from 'react';

const getDocsRoutes = () => {
  const InternalAPIPage = lazy(() => import('./InternalAPI'));
  return (
    <>
      <Route
        path="internal-api"
        element={
          <Suspense fallback={<div>Loading</div>}>
            <InternalAPIPage />
          </Suspense>
        }
      />
    </>
  );
};
export default getDocsRoutes;
