import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ContainerLookupContext from './ContainerLookupContext';
import { SidebarIdentifier } from '../../../types';
import { SllId, SllType } from '@/types/Sll';
import useUrlStore from '@/modules/url-store/hooks/useUrlStore.tsx';

// importing directly due to reloading issue I was seeing when using the UrlStoreProvider
// TODO: Use UrlStore Provider instead
import getQueryStringFromObject from '@/modules/url-store/utils/getQueryStringFromObject';
import getQueryStringParams from '@/modules/url-store/utils/getQueryStringParams';

type LookupProviderProps = {
  children: React.ReactNode;
};

const ContainerLookupProvider = ({ children }: LookupProviderProps) => {
  const navigate = useNavigate();
  const urlStore = useUrlStore();
  const { identifier = '' } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedItemId = searchParams.get('selectedItemId') || '';
  const selectedItemName = searchParams.get('selectedItemName') || '';
  const formPath = searchParams.get('formPath') || '';
  const formQueryString = searchParams.get('formQueryString') || '';
  const searchTerm = searchParams.get('searchTerm') || '';
  const [debouncedSearchTerm, setSearchTerm] = useState(searchTerm);

  const sllId = searchParams.get('sllId') || '';

  const category = searchParams.get('category') || '';

  const sidebarSelection = useMemo(
    () => ({
      identifier: identifier as SidebarIdentifier,
      sllId,
    }),
    [identifier, sllId],
  );

  const formUrl = `${formPath}${formQueryString}`;

  const saveSearchTerm = (searchTerm: string) => {
    console.log('ContainerLookupProvider.setSearchTerm  ', searchTerm);
    urlStore.api?.updateQueryString(
      { searchTerm: searchTerm },
      { replace: true },
    );
  };

  const setSelectedItem = (item: { id: SllId; name: string }) =>
    setSearchParams((prev) => {
      prev.set('selectedItemId', item.id);
      prev.set('selectedItemName', item.name);
      return prev;
    });

  const setCategory = (category: SllType) =>
    setSearchParams((prev) => {
      prev.set('category', category);
      return prev;
    });

  const setSidebarSelection = useCallback(
    (identifier: SidebarIdentifier, sllId: SllId = '') => {
      setSearchParams((prev) => {
        prev.set('sllId', sllId);
        return prev;
      });
      const url = `/lookup/container/${identifier}/?${searchParams.toString()}`;
      navigate(url);
    },
    [setSearchParams, navigate, searchParams],
  );

  // TODO: deal with these urls better
  const setContainerType = (category: SllType | null) => {
    setCategory(category || '');
    const url = `/lookup/container/${identifier}/items?${searchParams.toString()}`;
    navigate(url);
  };

  const clearContainerType = () => {
    setSearchParams((prev) => {
      prev.delete('category');
      return prev;
    });
    const url = `/lookup/container/${identifier}/?${searchParams.toString()}`;
    navigate(url);
  };

  const clearSelectedItem = () => {
    setSelectedItem({ id: '', name: '' });
  };

  const onSubmit = () => {
    const formParams = getQueryStringParams(formQueryString);
    const url = `${formPath}?${getQueryStringFromObject({
      ...formParams,
      selectedItemId,
      selectedItemName,
    })}`;
    navigate(url);
  };

  const value = {
    sidebarSelection,
    category,
    searchTerm,
    debouncedSearchTerm,
    selectedItemId,
    selectedItemName,
    formUrl,
    saveSearchTerm,
    setSearchTerm,
    setCategory,
    setContainerType,
    setSidebarSelection,
    clearContainerType,
    clearSelectedItem,
    setSelectedItem,
    onSubmit,
  };

  return (
    <ContainerLookupContext.Provider value={value}>
      {children}
    </ContainerLookupContext.Provider>
  );
};

export default ContainerLookupProvider;
