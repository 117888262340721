import logger from '@utils/Logger';
import { getAuthToken } from '@modules/auth/api';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
// import HttpError from 'src/errors/HttpError.ts';

export interface Params {
  [key: string]: any;
}

declare type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface IModifyingService {
  post<T>(
    url: string,
    body: Record<string, any>,
    options?: HttpRequestOptions,
  ): Promise<T>;

  del<T>(
    url: string,
    body?: Record<string, any>,
    options?: HttpRequestOptions,
  ): Promise<T>;

  put?: <T>(
    url: string,
    body: Record<string, any>,
    options?: HttpRequestOptions,
  ) => Promise<T>;

  patch?: <T>(
    url: string,
    data: Record<string, any>,
    options?: HttpRequestOptions,
  ) => Promise<T>;
}

export interface IHttpService extends IModifyingService {
  get<T>(url: string, options?: HttpRequestOptions): Promise<T>;
}

export interface IHttpServiceAdvanced extends IHttpService {
  makeRequest<T>(options: HttpRequestOptions): Promise<T>;
  enableErrors(): void;
  disableErrors(): void;
  addTempHeader(key: string, value: string): IHttpServiceAdvanced;
  retryWithDelay: (
    fn: () => any,
    retries: number,
    interval: number,
    finalErr: string,
  ) => Promise<any>;
}

// HTTP Request interface
export type HttpRequestOptions = {
  url?: string; // Resource url
  method?: HttpMethods; // HTTP method name
  query?: Params; // Object with query parameters
  data?: Params; // Object with data parameters
  headers?: Params; // Object with request http headers
};

const wait = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(null), ms);
  });
};

export default class HttpService implements IHttpServiceAdvanced {
  private _shouldBroadcastErrors: boolean = true;

  private _tempHeaders: { [key: string]: string } = {};

  enableErrors(): void {
    this._shouldBroadcastErrors = true;
  }

  disableErrors(): void {
    this._shouldBroadcastErrors = false;
  }

  /*
   * HTTP GET Request
   */
  get<T>(url: string, options?: HttpRequestOptions): Promise<T> {
    return this.makeRequest({
      url,
      method: 'GET',
      ...options,
    });
  }

  /*
   * HTTP POST Request
   */
  post<T>(
    url: string,
    data: Record<string, any>,
    options?: HttpRequestOptions,
  ): Promise<T> {
    return this.makeRequest({
      url,
      method: 'POST',
      data,
      ...options,
    });
  }

  /*
   * HTTP PUT Request
   */
  put<T>(
    url: string,
    data: Record<string, any>,
    options?: HttpRequestOptions,
  ): Promise<T> {
    return this.makeRequest({
      url,
      method: 'PUT',
      data,
      ...options,
    });
  }

  /*
   * HTTP PATCH Request
   */
  patch<T>(
    url: string,
    data: Record<string, any>,
    options?: HttpRequestOptions,
  ): Promise<T> {
    return this.makeRequest({
      method: 'PATCH',
      url,
      data,
      ...options,
    });
  }

  /*
   * HTTP DELETE Request
   */
  del<T>(
    url: string,
    data: Record<string, any>,
    options?: HttpRequestOptions,
  ): Promise<T> {
    return this.makeRequest({
      url,
      method: 'DELETE',
      data,
      ...options,
    });
  }

  // TODO: Add the ability to cancel requests.
  cancelRequest(): Promise<boolean> {
    return Promise.resolve(false);
  }

  public async retryWithDelay<R>(
    fn: () => any,
    retries = 10,
    interval = 5000,
    finalErr = 'Request timeout',
  ): Promise<R> {
    console.log('retryWithDelay', fn);
    try {
      // try
      const result = await fn();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result;
    } catch (err) {
      // if no retries left
      // throw error
      console.log(err);
      if (retries <= 0) {
        return Promise.reject(finalErr);
      }

      //delay the next call
      await wait(interval);

      //recursively call the same func
      return this.retryWithDelay(fn, retries - 1, interval, finalErr);
    }
  }

  /*
   * Common method for making requests.
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async makeRequest<T>(options: HttpRequestOptions): Promise<T> {
    const { url, data, method, headers: reqHeaders } = options;

    const headers = this.generateRequestHeaders(reqHeaders);

    // clean out temp headers after each request.
    this._tempHeaders = {};

    const requestOptions: AxiosRequestConfig = {
      url,
      method,
      headers,
      data: JSON.stringify(data),
    };

    logger.debug(
      'HttpService.makeRequest',
      method,
      url,
      requestOptions,
      headers,
    );

    try {
      const responseRaw: AxiosResponse<any, any> = await axios(requestOptions);
      const response = (await responseRaw.data) as T;
      return response;
    } catch (error: unknown) {
      console.error(error);
      throw error;
      if (this._shouldBroadcastErrors) {
        // send error notification
        console.log('HttpService.makeRequest  show error in toast', error);
      }
    }
  }

  addTempHeader(key: string, value: string): IHttpServiceAdvanced {
    this._tempHeaders = {
      ...this._tempHeaders,
      [key]: value,
    };
    return this;
  }

  /*
   * Generate request headers
   * - Sets basic parameters for all requests
   * - Adds auth if available
   */
  private generateRequestHeaders(reqHeaders?: Params): Record<string, string> {
    let headers: Record<string, string> = {
      ...reqHeaders,
      ...this._tempHeaders,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    const authToken: string | null = getAuthToken();

    if (authToken) {
      headers = {
        ...headers,
        Authorization: `Bearer ${authToken}`,
      };
    }

    return headers;
  }
}
